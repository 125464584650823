import {Component, OnInit} from '@angular/core';
import {SppBluService} from './systems/services/linker/spp-blu.service';
import * as XLSX from 'xlsx';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {WorkSheet} from 'xlsx';
import {ReferenceDataService} from './systems/services/reference-data.service';
import {ReferenceDataModel} from './models/reference/reference-data.model';
import {RkaklService} from './systems/services/linker/rkakl.service';
import {RkaklModel} from './models/data';
import {ExcelService} from './systems/services/excel.service';
import localeID from '@angular/common/locales/id';
import {registerLocaleData} from '@angular/common';
import {ResponseBase} from './systems/base/response-base';
import {error} from 'util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'monitoring-keuangan-bp3ip';
  fileToUpload: File = null;
  storeData: any;
  worksheet: any;
  referenceData: ReferenceDataModel;
  constructor(
    private referenceDataService: ReferenceDataService,
    private rkaklService: RkaklService,
    private sppBluService: SppBluService,
    private excelService: ExcelService) {

  }

  ngOnInit() {
    registerLocaleData(localeID, 'id-ID');
    this.referenceData = this.referenceDataService.getReferenceData(2019);
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);

    // this.rkaklService.parseData(this.fileToUpload, 2019, '02212', '414324', 'KU', this.referenceData).then((rkakls: RkaklModel[]) => {
    //   // const data = XLSX.utils.sheet_to_json(worksheet, {header: 1});
    //   console.log(rkakls);
    //   // this.excelService.exportAsExcelFile(rkakls, 'rkakl-output-2019');
    // });

    // this.sppBluService.parseData(this.fileToUpload, 2019, '02212', '414324', 'KU', this.referenceData).then(() => {
    //
    // });
  }
}
