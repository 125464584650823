export enum ApiPath {
  NONE = '',
  AUTH_LOGIN = 'user/secure/login',
  AUTH_LOGOUT = 'user/secure/logout',
  AUTH_CHANGE_PROFILE = 'user/secure/change/profile',
  AUTH_CHANGE_PASSWORD = 'user/secure/change/password',
  AUTH_CHANGE_PICTURE = 'user/secure/change/picture',
  AUTH_ADD_PROFILE = 'user/secure/add/user',
  AUTH_RESET_PASSWORD = 'user/secure/forgot',
//  ------------------------------ DASHBOARD
  DASHBOARD_CURVA_INFO = 'info/curva/p:SATKER_CODE/p:TA/show/base',
  DASHBOARD_CURVA_DATA = 'info/curva-data/p:SATKER_CODE/p:TA/show/base',
  DASHBOARD_TARGET_INFO = 'info/target/',
  REPORTING_PENYERAPAN_INFO = 'test/penyerapan/',
  REPORTING_PENYERAPAN_DATA_INFO = 'test/penyerapan-data/',
  REPORTING_MONITORING_INFO = 'test/absorption/unit/',
  REPORTING_TARGET_INFO = 'test/target/',
//  ------------------------------ REALIZATION
  PROGNOSA_FORM_DATA = 'realization/prognosa/',
  PROGNOSA_PHYSICAL_FORM_DATA = 'realization/prognosa/physical/',
  PROGNOSA_LOOKUP_INIT_DATA = 'realization/prognosa/lookup/init/',
  PROGNOSA_LOOKUP_PROGRAM_DATA = 'realization/prognosa/lookup/program/',
  PROGNOSA_LOOKUP_KEGIATAN_DATA = 'realization/prognosa/lookup/kegiatan/',
  PROGNOSA_LOOKUP_OUTPUT_DATA = 'realization/prognosa/lookup/output/',
  PROGNOSA_LOOKUP_SUB_OUTPUT_DATA = 'realization/prognosa/lookup/sub-output/',
  PROGNOSA_LOOKUP_KOMPONEN_DATA = 'realization/prognosa/lookup/komponen/',
  REALIZATION_RECAP_DATA = 'realization/recap/',
  REALIZATION_DAILY_DATA = 'realization/daily/',
  REALIZATION_MONTHLY_DATA = 'realization/monthly/',
  REPORTING_REALIZATION_MONTHLY_DATA = 'test/monthly/',
  REALIZATION_DETAIL_RECAP_DATA = 'realization/detail-recap/',
  REPORTING_REALIZATION_DETAIL_RECAP_DATA = 'test/detail-recap/',
//  ------------------------------ MONITORING
  MONITORING_ABSORPTION_BY_UNIT_GRAPH = 'monitoring/absorption/unit-graph/',
  MONITORING_ABSORPTION_BY_UNIT = 'monitoring/absorption/unit/',
  MONITORING_ABSORPTION_BY_SECTION = 'monitoring/absorption/section/',
  MONITORING_ABSORPTION_ACCOUNT_BY_UNIT = 'monitoring/absorption-account/unit/',
  MONITORING_ABSORPTION_ACCOUNT_BY_SECTION = 'monitoring/absorption-account/section/',
  MONITORING_OUTPUT_AKUN = 'monitoring/output/akun/',
  REPORTING_MONITORING_ABSORPTION_BY_UNIT = 'test/absorption/unit/',
  REPORTING_MONITORING_ABSORPTION_ACCOUNT_BY_UNIT = 'test/absorption-account/unit/',
  REPORTING_MONITORING_OUTPUT_AKUN = 'test/output/akun/',
//  ------------------------------ SYSTEMS
  LINKER_RKAKL = 'sys/linker/rkakl/linker-rkakl-process/show/base',
  LINKER_RKAKL_PROGNOSA_PHYSICAL = 'sys/linker/rkakl/linker-rkakl-prognosa-physical/show/base',
  LINKER_RKAKL_PROGNOSA_TARGET = 'sys/linker/rkakl/linker-rkakl-prognosa-target/show/base',
  LINKER_RKAKL_REVISION_CURRENT_PREVIOUS = 'sys/linker/rkakl/linker-rkakl-checking-revision/show/base',
  LINKER_RKAKL_REVISION_TO_REALIZATION = 'sys/linker/rkakl/linker-rkakl-revision-realization/show/base',
  LINKER_SPP_BLU = 'sys/linker/spp-blu/linker-spp-blu-process/show/base',
  LINKER_SPP_RM = 'sys/linker/spp-rm/linker-spp-rm-process/show/base',
  IMPORT_REVENUE = 'sys/import/revenue/show/base',
//  ------------------------------ MASTER
  DM_VENDORS = 'data/lookup/vendors/show/base',
  LOOKUP_DIPA_REVISION = 'data/lookup/dipa-revision/show/base',
//  ------------------------------ LOOKUP
  LOOKUP_PROGRAM_DATA = 'data/lookup/program/show/base',
  LOOKUP_KEGIATAN_DATA = 'data/lookup/kegiatan/show/base',
  LOOKUP_OUTPUT_DATA = 'data/lookup/output/show/base',
  LOOKUP_SUB_OUTPUT_DATA = 'data/lookup/sub-output/show/base',
  LOOKUP_KOMPONEN_DATA = 'data/lookup/komponen/show/base',
  LOOKUP_SUB_KOMPONEN_DATA = 'data/lookup/sub-komponen/show/base',
  LOOKUP_AKUN_DATA = 'data/lookup/akun/show/base',
  LOOKUP_DETAIL_DATA = 'data/lookup/detail/show/base',
  LOOKUP_SUB_DETAIL_DATA = 'data/lookup/sub-detail/show/base',
  AVAILABLE_ACCOUNT_INFO_DATA = 'data/ketersediaan-dana/akun/show/base',
  LOOKUP_EMPLOYEE_DATA = 'data/lookup/employee/show/base',
//  ------------------------------ WORKFLOW
  WORKFLOW_SPP_BLU_SUBMIT = 'workflow/blu-procurement/add/show/base',
  WORKFLOW_SPP_BLU_LOAD = 'workflow/blu-procurement/show/base',
  WORKFLOW_SPP_BLU_DATA = 'workflow/blu-procurement/data/show/base',
  WORKFLOW_SPP_BLU_DELETE = 'workflow/blu-procurement/delete/show/base',
  WORKFLOW_TAX_LOAD = 'workflow/blu-procurement/tax/show/base',
//  ------------------------------ WORKFLOW
  REPORTING_SPP = 'test/report/spp/show/base',
  REPORTING_SPP_SPMI = 'test/report/spmi/show/base',
  REPORTING_SPP_BAP = 'test/report/bap/show/base',
  REPORTING_SPP_SP2DI = 'test/report/sp2di/show/base',
  REPORTING_SPP_SPTJB = 'test/report/sptjb/show/base',
//  ------------------------------ DATA
  CURRENT_DIPA_REVISION = 'data/current/dipa-revision/show/base',
//  ------------------------------ BO OPS
  BO_OPS_USER_APP_DATA = 'bo-ops/user/app/data/show/base',
  BO_OPS_USER_CURRENT_EMAIL_DATA = 'bo-ops/user/current/email/data/show/base',
  BO_OPS_USER_REGISTER = 'bo-ops/user/secure/register/show/base',
  BO_OPS_USER_UPDATE = 'bo-ops/user/secure/update/show/base',
  BO_OPS_USER_MODULE_ROLES = 'bo-ops/user/module-role/data/show/base',
  BO_OPS_USER_MODULE_ROLES_UPDATE = 'bo-ops/user/module-role/update/show/base',
  BO_OPS_USER_DATA_ROLES = 'bo-ops/user/data-role/data/show/base',
  BO_OPS_USER_DATA_ROLES_UPDATE = 'bo-ops/user/data-role/update/show/base',
  // BO_OPS_USER_DATA_ROLES = 'bo-ops/user/data-role/data/',
  BO_OPS_APP_ROLES = 'bo-ops/app-role/data/',
  BO_OPS_SIGNING_OFFICER_DETAIL = 'bo-ops/signing-officer/detail/show/base',
  BO_OPS_SIGNING_OFFICER_UPDATE = 'bo-ops/signing-officer/update/show/base',
  BO_OPS_MASTER_PROVIDERS = 'bo-ops/providers/data/',
  BO_OPS_MASTER_EMPLOYEES = 'bo-ops/employees/data/',

  //  ----------------------------- PENDAPATAN
  PENDAPATAN_MONTHLY = 'revenue/monthlya/',
  PENDAPATAN_DAILY = 'revenue/daily/'
}
