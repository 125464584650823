import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {ApiPath} from '../enumeration/api-path.enum';
import {LsPathEnum} from '../enumeration/ls-path.enum';
import {UserModel} from '../../models/auth';
import {LevelModel} from '../../models/reference';
import {isUndefined} from 'util';
import {ResponseBase} from '../base/response-base';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  static makeRand(strLength: number) {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < strLength; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  login(email: string, password: string) {
    return this.http.post<any>(environment.api_url + environment.base_api + ApiPath.AUTH_LOGIN, { email, password }, {
      headers: new HttpHeaders({
        // Authorization: `Bearer ${environment.app_key}`,
        'Content-XType-App': `${environment.app_name}`,
        'Content-XType-Package': `${environment.app_package}`,
        'Content-XType-Version': `${environment.app_version}`,
        'Content-XType-BuildNumber': `${environment.app_build_number}`,
        }
      )
    });
  }

  resetPassword(email: string) {
    return this.http.post<any>(environment.api_url + environment.base_api + ApiPath.AUTH_RESET_PASSWORD, {email}, {
      headers: new HttpHeaders({
          // Authorization: `Bearer ${environment.app_key}`,
          'Content-XType-App': `${environment.app_name}`,
          'Content-XType-Package': `${environment.app_package}`,
          'Content-XType-Version': `${environment.app_version}`,
          'Content-XType-BuildNumber': `${environment.app_build_number}`,
        }
      )
    });
  }

  logout() {
    localStorage.removeItem(`${environment.base_key}${LsPathEnum.LS_USER}`);
    localStorage.removeItem(`${environment.base_key}${LsPathEnum.LS_TOKEN}`);
    localStorage.removeItem(`${environment.base_key}${LsPathEnum.LS_LEVEL}`);
    localStorage.removeItem(`${environment.base_key}${LsPathEnum.LS_ROLE}`);
    localStorage.removeItem(`${environment.base_key}${LsPathEnum.LS_BAGIAN}`);
    // localStorage.removeItem(`${environment.base_key}${LsPathEnum.LS_SATKER}`);
    // localStorage.removeItem(`${environment.base_key}${LsPathEnum.LS_SECTION}`);
  }

  loggedIn(): any {
    return !!localStorage.getItem(`${environment.base_key}${LsPathEnum.LS_TOKEN}`);
  }

  getToken() {
    return localStorage.getItem(`${environment.base_key}${LsPathEnum.LS_TOKEN}`);
  }

  setToken(token: string) {
    localStorage.setItem(`${environment.base_key}${LsPathEnum.LS_TOKEN}`, token);
  }

  setUser(user: UserModel) {
    if (user !== null || user !== undefined) {
      const b64String = btoa(JSON.stringify(user));
      localStorage.setItem(`${environment.base_key}${LsPathEnum.LS_USER}`, AuthService.makeRand(133) + b64String);
    }
  }

  setLevel(level: LevelModel) {
    if (level !== null || !isUndefined(level)) {
      const b64String = btoa(JSON.stringify(level));
      localStorage.setItem(`${environment.base_key}${LsPathEnum.LS_LEVEL}`, AuthService.makeRand(75) + b64String);
    }
  }

  setRole(role: any) {
    if (role !== null || !isUndefined(role)) {
      const b64String = btoa(JSON.stringify(role));
      localStorage.setItem(`${environment.base_key}${LsPathEnum.LS_ROLE}`, AuthService.makeRand(66) + b64String);
    }
  }

  constructor(private http: HttpClient) { }

}
