export enum LsPathEnum {
  LS_TOKEN = '-token',
  LS_USER = '-namespace-spec',
  LS_LEVEL = '-view-spec',
  LS_SATKER = '-domain-spec',
  LS_SECTION = '-section-spec',
  LS_ROLE = '-runtime-spec',
  LS_TA = '-ta',
  LS_BAGIAN = '-current-spec'
}
