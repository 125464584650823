import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {ReferenceDataService} from './systems/services/reference-data.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ExcelService} from './systems/services/excel.service';
import {AppLayoutComponent} from './layouts/app-layout/app-layout.component';
import {SignInLayoutComponent} from './layouts/sign-in-layout/sign-in-layout.component';
import {AppHeaderLayoutComponent} from './layouts/app-header-layout/app-header-layout.component';
import {AppNavigationLayoutComponent} from './layouts/app-navigation-layout/app-navigation-layout.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ErrorInterceptor, JwtInterceptor} from './systems/interceptors';
import {AppsService} from './systems/services/apps.service';
import {ApiService} from './systems/services/api.service';
import {AuthService} from './systems/services/auth.service';
import {UiService} from './systems/services/ui.service';
import {DxModule} from './dx.module';
import {AngularResizedEventModule} from 'angular-resize-event';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastContainerModule, ToastrModule} from 'ngx-toastr';
import {ReportingModule} from './workspace/reporting/reporting.module';
import {FlipModule} from 'ngx-flip';
import {RoleService} from './systems/services/role.service';

@NgModule({
  declarations: [
    AppComponent,
    SignInLayoutComponent,
    AppLayoutComponent,
    AppHeaderLayoutComponent,
    AppNavigationLayoutComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({ positionClass: 'toast-top-right', progressBar: true }), // ToastrModule added
    ToastContainerModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ReportingModule,
    FlipModule
  ],
  providers: [
    ReferenceDataService,
    ExcelService,
    AuthService,
    AppsService,
    RoleService,
    ApiService,
    UiService,
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
