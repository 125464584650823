import {Guid} from 'guid-typescript';
import {ReferenceDataModel} from '../reference/reference-data.model';

export class RkaklModel {
  IdGuid: string;
  TA: number;
  ba: string;
  baEs1: string;
  kdSatker: string;
  kdBagian: string;
  program: string;
  kegiatan: string;
  output: string;
  outputVolume: number;
  outputSatuan: string;
  subOutput: string;
  subOutputVolume: number;
  subOutputSatuan: string;
  komponen: string;
  subKomponen: string;
  akun: string;
  name: string;
  detail: string;
  subDetail: string;
  volume: number;
  satuan: string;
  hargaSatuan: number;
  hargaTotal: number;
  kewenangan: string;
  kodeBlokir: string;
  sumberDana: string;
  lokasi: string;
  wilayah: string;
  mnkRefKementerianID: number;
  mnkRefEs1ID: number;
  mnkRefSatkerID: number;
  mnkRefBagianID: number;
  mnkRefProgramID: number;
  mnkRefKegiatanID: number;
  mnkRefOutputID: number;
  mnkRefSubOutputID: number;
  mnkRefKomponenID: number;
  mnkRefSubKomponenID: number;
  mnkRefAkunLv6ID: number;
  mnkRefKewenanganID: number;
  mnkRefSumberDanaID: number;
  mnkRefLokasiID: number;
  mnkRefWilayahID: number;
  mnkRkaklRevision: number;
  namaProgram: string;
  namaKegiatan: string;
  namaOutput: string;
  namaSubOutput: string;
  namaKomponen: string;
  namaSubKomponen: string;
  bidang: string;
  constructor() { }
  //
  // constructor(TA: number, data: any, referenceData: ReferenceDataModel) {
  //   this.IdGuid = Guid.create().toString();
  //   this.TA = TA;
  //   this.ba = data.ba;
  //   this.baEs1 = data.baEs1;
  //   this.kdSatker = data.kdsatker;
  //   this.kdBagian = data.kdBagian;
  //   this.program = data.program;
  //   this.kegiatan = data.kegiatan;
  //   this.output = data.output;
  //   this.subOutput = data.subOutput;
  //   this.komponen = data.komponen;
  //   this.subKomponen = data.subKomponen;
  //   this.akun = data.akun;
  //   this.detail = data.detail;
  //   this.subDetail = data.subDetail;
  //   this.volume = data.volume;
  //   this.satuan = data.satuan;
  //   this.hargaSatuan = data.hargaSatuan;
  //   this.kewenangan = data.kewenangan;
  //   this.sumberDana = data.sumberDana;
  //   this.lokasi = data.lokasi;
  //   this.mnkRefKementerianID = this._getKementerianID(data.ba, referenceData);
  //   this.mnkRefEs1ID = this._getEselon1ID(data.baEs1, referenceData);
  //   this.mnkRefSatkerID = this._getSatkerID(data.kdsatker, referenceData);
  //   this.mnkRefBagianID = this._getBagianID(data.bagian, referenceData);
  //   this.mnkRefProgramID = this._getProgramID(data.program, referenceData);
  //   this.mnkRefKegiatanID = this._getKegiatanID(data.kegiatan, referenceData);
  //   this.mnkRefOutputID = this._getOutputID(data.output, referenceData);
  //   this.mnkRefSubOutputID = this._getSubOutputID(data.subOutput, referenceData);
  //   this.mnkRefKomponenID = this._getKomponenID(data.komponen, referenceData);
  //   this.mnkRefSubKomponenID = this._getSubKomponenID(data.subKomponen, referenceData);
  //   this.mnkRefAkunLv6ID = this._getAkunLv6ID(data.akun, referenceData);
  //   this.mnkRefKewenanganID = this._getKewenanganID(data.kewenangan, referenceData);
  //   this.mnkRefSumberDanaID = this._getSumberDanaID(data.sumberDana, referenceData);
  //   this.mnkRefLokasiID = this._getLokasiID(data.lokasi, referenceData);
  //   this.mnkRefWilayahID = this._getWilayahID(data.lokasi.substring(0, 2), referenceData);
  //   // this.mnkRkaklRevision = this._getRkaklRevision(data.revision, referenceData);
  // }

  public static async initRelation(currentData: RkaklModel, referenceData: ReferenceDataModel) {
    // currentData.mnkRefKementerianID = await this._getKementerianID(currentData, currentData.ba, referenceData);
    // currentData.mnkRefEs1ID = await this._getEselon1ID(currentData, currentData.baEs1, referenceData);
    // currentData.mnkRefSatkerID = await this._getSatkerID(currentData, currentData.kdSatker, referenceData);
    // currentData.mnkRefBagianID = await this._getBagianID(currentData, currentData.kdBagian, referenceData);
    // currentData.mnkRefProgramID = await this._getProgramID(currentData, currentData.program, referenceData);
    // currentData.mnkRefKegiatanID = await this._getKegiatanID(currentData, currentData.kegiatan, referenceData);
    // currentData.mnkRefOutputID = await this._getOutputID(currentData, currentData.output, referenceData);
    // currentData.mnkRefSubOutputID = await this._getSubOutputID(currentData, currentData.subOutput, referenceData);
    // currentData.mnkRefKomponenID = await this._getKomponenID(currentData, currentData.komponen, referenceData);
    // currentData.mnkRefSubKomponenID = await this._getSubKomponenID(currentData, currentData.subKomponen, referenceData);
    // currentData.mnkRefAkunLv6ID = await this._getAkunLv6ID(currentData, currentData.akun, referenceData);
    // currentData.mnkRefKewenanganID = await this._getKewenanganID(currentData, currentData.kewenangan, referenceData);
    // currentData.mnkRefSumberDanaID = await this._getSumberDanaID(currentData, currentData.sumberDana, referenceData);
    // currentData.mnkRefLokasiID = await this._getLokasiID(currentData, currentData.lokasi, referenceData);
    // currentData.mnkRefWilayahID = await this._getWilayahID(currentData, currentData.lokasi.substring(0, 2), referenceData);
    currentData.mnkRefKementerianID = await this._getKementerianID(currentData, currentData.ba, referenceData);
    currentData.mnkRefEs1ID = await this._getEselon1ID(currentData, currentData.baEs1, referenceData);
    currentData.mnkRefSatkerID = await this._getSatkerID(currentData, currentData.kdSatker, referenceData);
    currentData.mnkRefBagianID = -1;
    currentData.mnkRefProgramID = -1;
    currentData.mnkRefKegiatanID = -1;
    currentData.mnkRefOutputID = -1;
    currentData.mnkRefSubOutputID = -1;
    currentData.mnkRefKomponenID = -1;
    currentData.mnkRefSubKomponenID = -1;
    currentData.mnkRefAkunLv6ID = -1;
    currentData.mnkRefKewenanganID = await this._getKewenanganID(currentData, currentData.kewenangan, referenceData);
    currentData.mnkRefSumberDanaID = await this._getSumberDanaID(currentData, currentData.sumberDana, referenceData);
    currentData.mnkRefLokasiID = await this._getLokasiID(currentData, currentData.lokasi, referenceData);
    currentData.mnkRefWilayahID = await this._getWilayahID(currentData, currentData.lokasi.substring(0, 2), referenceData);
    return currentData;
  }

  private static async _getKementerianID(currentData: RkaklModel, ba: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.KEMENTERIAN_DATA.find( c => c.KodePa === ba);
    return o ? o.IdPa : -1;
  }

  private static async _getEselon1ID(currentData: RkaklModel, baEs1: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.ESELON1_DATA.find( c => c.KodePaEselon1 === baEs1);
    return o ? o.IdPaEselon1 : -1;
  }

  private static async _getSatkerID(currentData: RkaklModel, kdSatker: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.SATKER_DATA.find( c => c.KodePaSatker === kdSatker);
    return o ? o.IdPaSatker : -1;
  }

  private static async _getBagianID(currentData: RkaklModel, bagian: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.BAGIAN_DATA.find( c => c.KodeBagian === bagian);
    return o ? o.IdBagian : -1;
  }

  private static async _getProgramID(currentData: RkaklModel, program: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.PROGRAM_DATA.find( c => c.KodeProgram === program);
    return o ? o.IdProgram : -1;
  }

  private static async _getKegiatanID(currentData: RkaklModel, kegiatan: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.KEGIATAN_DATA.find( c => c.KodeKegiatan === kegiatan && c.ProgramID === currentData.mnkRefProgramID);
    return o ? o.IdKegiatan : -1;
  }

  private static async _getOutputID(currentData: RkaklModel, output: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.OUTPUT_DATA.find( c => c.KodeOutput === output && c.KegiatanID === currentData.mnkRefKegiatanID);
    return o ? o.IdOutput : -1;
  }

  private static async _getSubOutputID(currentData: RkaklModel, subOutput: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.SUB_OUTPUT_DATA.find( c => c.KodeSubOutput === subOutput && c.OutputID === currentData.mnkRefOutputID);
    return o ? o.IdSubOutput : -1;
  }

  private static async _getKomponenID(currentData: RkaklModel, komponen: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.KOMPONEN_DATA.find( c => c.KodeKomponen === komponen && c.SubOutputID === currentData.mnkRefSubOutputID);
    return o ? o.IdKomponen : -1;
  }

  private static async _getSubKomponenID(currentData: RkaklModel, subKomponen: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.SUB_KOMPONEN_DATA.find( c => c.KodeSubKomponen === subKomponen &&
      c.KomponenID === currentData.mnkRefKomponenID);
    return o ? o.IdSubKomponen : -1;
  }

  private static async _getAkunLv6ID(currentData: RkaklModel, akun: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.AKUNLV6_DATA.find( c => c.KodeAkunLv6 === Number(akun));
    return o ? o.IdAkunLv6 : -1;
  }

  private static async _getKewenanganID(currentData: RkaklModel, kewenangan: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.KEWENANGAN_DATA.find( c => c.IdKewenangan === Number(kewenangan));
    return o ? o.IdKewenangan : -1;
  }

  private static async _getSumberDanaID(currentData: RkaklModel, sumberDana: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.SUMBERDANA_DATA.find( c => c.Code === sumberDana);
    return o ? o.IdSumberDana : -1;
  }

  private static async _getLokasiID(currentData: RkaklModel, lokasi: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.LOKASI_DATA.find( c => c.KodeLokasi === lokasi);
    return o ? o.IdLokasi : -1;
  }

  private static async _getWilayahID(currentData: RkaklModel, wilayah: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.WILAYAH_DATA.find( c => c.KodeWilyah === wilayah);
    return o ? o.IdWilayah : -1;
  }

  // private _getRkaklRevision(revision: string, referenceData: ReferenceDataModel): any {
  //   const o = referenceData.WILAYAH_DATA.find( c => c.KodeWilyah === wilayah);
  //   return o ? o.IdWilayah : -1;
  // }
}


