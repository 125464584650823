import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AppLayoutComponent} from './layouts/app-layout/app-layout.component';
import {SignInLayoutComponent} from './layouts/sign-in-layout/sign-in-layout.component';
import {DashboardComponent} from './workspace/dashboard/dashboard.component';
import {AuthGuard} from './systems/providers/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: 'src/app/workspace/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'monitoring',
        loadChildren: 'src/app/workspace/monitoring/monitoring.module#MonitoringModule'
      },
      {
        path: 'realization',
        loadChildren: 'src/app/workspace/realization/realization.module#RealizationModule'
      },
      {
        path: 'detail-realization',
        loadChildren: 'src/app/workspace/detail-realization/detail-realization.module#DetailRealizationModule'
      },
      {
        path: 'sys',
        loadChildren: 'src/app/workspace/sys/sys.module#SysModule'
      },
      {
        path: 'flow',
        loadChildren: 'src/app/workspace/workflow/workflow.module#WorkflowModule'
      },
      {
        path: 'user-init',
        loadChildren: 'src/app/workspace/user-operations/user-operations.module#UserOperationsModule'
      },
      {
        path: 'bo-area',
        loadChildren: 'src/app/workspace/bo-area/bo-area.module#BoAreaModule'
      },
      {
        path: 'revenue',
        loadChildren: 'src/app/workspace/revenue/revenue.module#RevenueModule'
      }
    ]
  },
  {
    path: 'auth',
    component: SignInLayoutComponent
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
