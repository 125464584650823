import {EventEmitter, Injectable, Output} from '@angular/core';
import * as XLSX from 'xlsx';
import {RkaklModel} from '../../../models/data';
import {ReferenceDataModel} from '../../../models/reference/reference-data.model';
import {RkaklStateEnum} from '../../enumeration/rkakl-state.enum';
import {isNumber} from 'util';
import {isNumeric} from 'rxjs/internal-compatibility';
import {BagianModel} from '../../../models/reference/bagian.model';
import {LoaderModel, NotifyModel} from '../../../models/uis';
import {LinkerProgressModel} from '../../../models/shared/linker-progress.model';
import {Util} from '../../helpers/util.helper';
import {HttpErrorResponse} from '@angular/common/http';
import {ResponseBase} from '../../base/response-base';
import {environment} from '../../../../environments/environment';
import {ApiPath} from '../../enumeration/api-path.enum';
import {UserModel} from '../../../models/auth';
import {AppsService} from '../apps.service';
import {ApiService} from '../api.service';
import {Resolve} from "../../helpers/resolve.helper";

@Injectable({
  providedIn: 'root'
})
export class RkaklService {
  private dataProgressLinker: LinkerProgressModel;
  @Output() changeProgressLinker: EventEmitter<LinkerProgressModel> = new EventEmitter();
  constructor(private app: AppsService, private apiService: ApiService, ) {
    this.serviceData = [];
    this.kewenangan = '2';
  }

  storeData: any;
  worksheet: any;
  serviceData: RkaklModel[];
  ta: number;
  bagian: string;
  kewenangan: string;
  lokasi: string;
  wilayah: string;

  pa: string;
  paEselon1: string;
  paSatker: string;
  kodeProgram: string;
  namaProgram: string;
  kodeKegiatan: string;
  namaKegiatan: string;
  kodeOutput: string;
  namaOutput: string;
  outputVolume: number;
  outputSatuan: string;
  kodeSubOutput: string;
  namaSubOutput: string;
  subOutputVolume: number;
  subOutputSatuan: string;
  kodeKomponen: string;
  namaKomponen: string;
  kodeSubKomponen: string;
  namaSubKomponen: string;
  kodeAkun: string;
  namaAkun: string;
  detail: string;
  subDetail: string;
  volume: number;
  satuan: string;
  hargaSatuan: number;
  jumlahHarga: number;
  kodeBlokir: string;
  sumberDana: string;
  bidang: string;
  total = 0;

  currentState: RkaklStateEnum = RkaklStateEnum.Program;
  currentRowData: RkaklModel = new RkaklModel();
  referenceData: ReferenceDataModel;

  totalRowInSheetData = 0;
  currentRowInSheetData = 0;

  linkerIsFirstProcess = true;
  linkerIsSuccess = false;
  linkerIsError = false;
  linkerPercent = 0;
  linkerType: string;
  linkerRevision: number;
  linkerResponseRevision: number;
  adkCode: string;
  adkDate: any;
  initProgress(data: LinkerProgressModel) {
    this.dataProgressLinker = data;
    this.changeProgressLinker.emit(this.dataProgressLinker);
  }

  public async parseDataFirstSection(fileToParse: File, ta: number, adkCode: string, adkDate: any, userData: UserModel,
                                     referenceData: ReferenceDataModel, linkerType: string, revision: number = 0) {
    this.adkCode = adkCode;
    this.adkDate = adkDate;
    this.linkerType = linkerType;
    this.linkerRevision = revision;
    this.referenceData = referenceData;
    this.ta = ta;
    this.paEselon1 = userData.satker.KodePaEselon1;
    this.paSatker = userData.satker.KodePaSatker;
    this.bagian = userData.bagian === null ? null : userData.bagian.KodeBagian;
    this.lokasi = userData.satker.KodeLokasi;
    this.wilayah = userData.satker.KodeWilayah;
    return new Promise((resolve, reject) => {
      const readFile = new FileReader();

      readFile.onload = async (e) => {
        this.storeData = readFile.result;
        const data = new Uint8Array(this.storeData);
        const arr = [];
        for (let i = 0; i !== data.length; ++i) {
          arr[i] = String.fromCharCode(data[i]);
        }
        const binaryStr = arr.join('');
        const workbook = XLSX.read(binaryStr, {type: 'binary'});
        const firstSheetName = workbook.SheetNames[0];
        this.worksheet = workbook.Sheets[firstSheetName];
        await this.sinkWorksheetData(ta, this.bagian, referenceData);
        this.initDefaultLinker();
        resolve(new LinkerProgressModel(true,
          (this.currentRowInSheetData / this.totalRowInSheetData ) * 100, null));
      };

      readFile.onerror = reject;

      readFile.readAsArrayBuffer(fileToParse);
    });
  }

  public async parseDataBySection(fileToParse: File, ta: number, userData: UserModel, referenceData: ReferenceDataModel,
                                  linkerType: string, revision: number = 0) {

    // console.log(fileToParse);
    this.linkerType = linkerType;
    this.linkerRevision = revision;
    this.referenceData = referenceData;
    this.ta = ta;
    this.paEselon1 = userData.satker.KodePaEselon1;
    this.paSatker = userData.satker.KodePaSatker;
    this.bagian = userData.bagian === null ? null : userData.bagian.KodeBagian;
    this.lokasi = userData.satker.KodeLokasi;
    this.wilayah = userData.satker.KodeWilayah;
    return new Promise((resolve, reject) => {
      const readFile = new FileReader();

      readFile.onload = async (e) => {
        this.storeData = readFile.result;
        const data = new Uint8Array(this.storeData);
        const arr = [];
        for (let i = 0; i !== data.length; ++i) {
          arr[i] = String.fromCharCode(data[i]);
        }
        const binaryStr = arr.join('');
        const workbook = XLSX.read(binaryStr, {type: 'binary'});
        const firstSheetName = workbook.SheetNames[0];

        // console.log(firstSheetName);
        this.worksheet = workbook.Sheets[firstSheetName];
        // console.log(this.worksheet);

        await this.sinkWorksheetData(ta, this.bagian, referenceData);
        this.initDefaultLinker();
        resolve(new LinkerProgressModel(true,
          (this.currentRowInSheetData / this.totalRowInSheetData ) * 100, null));
      };

      readFile.onerror = reject;

      readFile.readAsArrayBuffer(fileToParse);
    });
  }

  public async downloadTemplate() {
    const url = `${environment.api_url}/api/v1/download/template?type=rkakl`;
    window.open(url, '_blank');
  }

  private initDefaultLinker() {
    this.linkerIsError = false;
    this.linkerIsFirstProcess = true;
    this.linkerPercent = 0;
    this.serviceData = [];
  }

  private async sinkWorksheetData(ta: number, bagian: string, referenceData: ReferenceDataModel) {
    const data = XLSX.utils.sheet_to_json(this.worksheet, {header: 1});
    
    this.totalRowInSheetData = data.length;
    //console.log(data);
    // return false;
    Util.logGroup('SINK DATA');
    // Util.log('SINK CODE ' + this.totalRowInSheetData);
    for (let i = 1; i < data.length; i++) {
      this.currentRowInSheetData = i;
      Util.log('SINK CODE ' + data[i][0]);
      Util.log('SINK ROW ' + (i + 1) + ' -- SINK NAME ' + data[i][1]);

      // console.log(data[i][1]); 
      

      this.kodeBlokir = null;
      switch (this.currentState) {
        case RkaklStateEnum.Program:
          Util.log("SINK IN PROGRAM");
          this.pa = data[i][0].toString().substring(0, 3);
          this.paEselon1 = data[i][0].toString().substring(0, 6).replace(/\./g, '');
          this.kodeProgram = data[i][0].toString().replace(/\./g, '');
          this.namaProgram = data[i][1].toString().trim();
          this.currentState = RkaklStateEnum.Activity;
          break;
        case RkaklStateEnum.Activity:
          Util.log("SINK IN ACTIVITY");
          console.log(data[i]);
          console.log('row ke_' + (i+1));
          
          this.kodeKegiatan = data[i][0];
          this.namaKegiatan = data[i][1].toString().trim();
          this.currentState = RkaklStateEnum.Output;
          break;
        case RkaklStateEnum.Output:
          Util.log("SINK IN OUTPUT");
          console.log(data[i]);
          console.log('row ke_' + (i+1));

          const outputStrCode = data[i][0].toString().trim();
          this.kodeOutput = outputStrCode.split('.')[1];
          this.namaOutput = data[i][1].replace('[Base Line]', '').trim();
          this.outputVolume = Number(data[i][2]);
          this.outputSatuan = data[i][3].trim();
          this.currentState = RkaklStateEnum.SubOutput;
          break;
        case RkaklStateEnum.SubOutput:
          Util.log("SINK IN SUB OUTPUT");
          console.log(data[i]);
          console.log('row ke_' + (i+1));

          const subOutputStrCode = data[i][0].toString().trim();
          if (subOutputStrCode.length === 12) {
            this.kodeSubOutput = subOutputStrCode.split('.')[2];
            this.namaSubOutput = data[i][1].toString().trim();
            this.subOutputVolume = Number(data[i][2]);
            this.subOutputSatuan = data[i][3].trim();
            this.currentState = RkaklStateEnum.Component;
          } else if (data[i][0].toString().trim().length === 3) {
            this.kodeSubOutput = '-';
            this.namaSubOutput = 'Tanpa Sub Output';
            this.kodeKomponen = data[i][0].toString().trim();
            this.namaKomponen = data[i][1].toString().trim();
            this.currentState = RkaklStateEnum.SubComponent;
          }
          break;
        case RkaklStateEnum.Component:
          Util.log("SINK IN COMPONENT");
          console.log(data[i]);
          console.log('row ke_' + (i+1));

          // Util.log(data);
          this.kodeKomponen = data[i][0].toString().trim();
          this.namaKomponen = data[i][1].toString().trim();
          this.kodeBlokir = Resolve.toString(data[i][6], true);//  data[i][6] !== undefined ? data[i][6].toString().trim() : null;
          this.currentState = RkaklStateEnum.SubComponent;
          break;
        case RkaklStateEnum.SubComponent:
          Util.log("SINK IN SUB COMPONENT");
          console.log(data[i]);
          console.log('row ke_' + (i+1));

          if(!data[i][0]) return alert(`format row ${(i + 1)} tidak sah, silahkan periksa atau hapus!!`)

          this.kodeSubKomponen = data[i][0].toString().trim();
          this.namaSubKomponen = data[i][1].toString().trim();
          this.currentState = RkaklStateEnum.Account;
          break;
        case RkaklStateEnum.Account:
          Util.log("SINK IN ACCOUNT");
          console.log(data[i]);
          console.log('row ke_' + (i+1));

          if(!data[i][0]) return alert(`format row ${(i + 1)} tidak sah, silahkan periksa atau hapus!!`)
          
          this.kodeAkun = data[i][0].toString().trim();
          this.namaAkun = data[i][1].toString().trim();
          this.kodeBlokir = Resolve.toString(data[i][6], true);
          this.sumberDana = data[i][7].toString().trim();
          this.currentState = RkaklStateEnum.Detail;
          break;
        case RkaklStateEnum.Detail:
          Util.log("SINK IN DETAIL");
          console.log(data[i]);
          console.log('row ke_' + (i+1));

          const strDataDetail = Resolve.toString(data[i][1]);
          if (strDataDetail.startsWith('>> ')) {
            this.detail = strDataDetail.toString().trim().replace(/\s\s/g, '');
            this.kodeBlokir = Resolve.toString(data[i][6], true);
            this.currentState = RkaklStateEnum.SubDetail;
          } else if (strDataDetail.startsWith('> ')) {
            this.detail = strDataDetail.toString().trim().replace(/\s\s/g, '');
            this.kodeBlokir = Resolve.toString(data[i][6], true);
            this.currentState = RkaklStateEnum.SubDetail;
          } else if (strDataDetail.startsWith('- ')) {
            this.detail = strDataDetail.toString().trim().replace(/\s\s/g, '');
            this.volume = Number(data[i][2]);
            this.satuan = data[i][3];
            this.hargaSatuan = Number(data[i][4]);
            this.jumlahHarga = Number(data[i][5]);
            this.total = this.total + (this.volume * this.hargaSatuan);
            this.bidang = data[i][8]
            this.kodeBlokir = Resolve.toString(data[i][6], true);
            const response: ResponseBase =
            await this.initRowData(false);
            if (!response.success) {
              break;
            }
          } else {
            // console.warn(`IN DETAIL => ${data[i][0].trim()}`);
            // const dataCode = data[i][0].toString().trim();
            // this.reverseState(dataCode, data, i, false);


            let dataCode = '';
            if (data[i][0] !== undefined) {
              dataCode = data[i][0].toString().trim();
            }
            this.reverseState(dataCode, data, i, false);
          }
          break;
        case RkaklStateEnum.SubDetail:
          Util.log("SINK IN SUB DETAIL");
          console.log(data[i]);
          console.log('row ke_' + (i+1));

          const strDataSubDetail = Resolve.toString(data[i][1]);
          if (strDataSubDetail.startsWith('- ')) {
            this.subDetail = strDataSubDetail.toString().trim().replace(/\s\s/g, '');
            this.volume = Number(data[i][2]);
            this.satuan = data[i][3];
            this.hargaSatuan = Number(data[i][4]);
            this.jumlahHarga = Number(data[i][5]);
            this.total = this.total + (this.volume * this.hargaSatuan);
            this.kodeBlokir = Resolve.toString(data[i][6], true);
            const response: ResponseBase =
            await this.initRowData(true);
            if (!response.success) {
              break;
            }
          } else {
            // console.warn(`IN SUB DETAIL => ${data[i][0].trim()}`);
            // console.log(data[i][0]);
            // const dataCode = data[i][0].trim();
            let dataCode = '';
            if (data[i][0] !== undefined) {
              dataCode = data[i][0].toString().trim();
            }

            this.reverseState(dataCode, data, i, true);
          }
          break;
        default:
          break;
      }
    }
    Util.logEndGroup();

    // return false

    if (this.linkerIsSuccess) {
      const responseEnd: ResponseBase = await this.sendProcessLinker();
      if (responseEnd.success) {
        this.linkerRevision = responseEnd.revision;
        this.linkerIsFirstProcess = false;
        this.linkerIsSuccess = true;
        this.linkerPercent = Math.round(((this.currentRowInSheetData) / this.totalRowInSheetData) * 100);
        this.initProgress(new LinkerProgressModel(true, this.linkerPercent, null));
        this.serviceData = [];
      } else {
        this.initProgress(new LinkerProgressModel(true, this.linkerPercent, responseEnd.message));
      }
    }

  }

  private reverseState(dataCode: string, data: any, i: number, subDetail: boolean) {
    Util.logGroup('REVERSE SINK');
    Util.log("REVERSE CODE " + dataCode);
    if (subDetail && dataCode.length === 0 && data[i][1].trim()) {
      // - NOT START > or >>
      Util.log("REVERSE IN LENGTH OF DETAIL");
      this.detail = data[i][1].toString().trim().replace(/\s\s/g, '');
      this.kodeBlokir = Resolve.toString(data[i][6], true);
      this.currentState = RkaklStateEnum.SubDetail;
    }
    else if (dataCode.length === 6 && isNumeric(dataCode)) {
      // 525112
      Util.log("REVERSE IN LENGTH OF ACCOUNT");
      this.kodeAkun = dataCode;
      this.namaAkun = data[i][1].toString().trim();
      this.kodeBlokir = Resolve.toString(data[i][6], true);
      this.sumberDana = data[i][7].toString().trim();
      this.currentState = RkaklStateEnum.Detail;
    }
    // else if (dataCode.length > 0 && !isNumeric(dataCode)) {
    else if (dataCode.length > 0 && dataCode.length <= 2 && /[A-Z]/.test(dataCode)) {
      // A or AA
      Util.log("REVERSE IN LENGTH OF SUB COMPONENT");
      this.kodeSubKomponen = dataCode;
      this.namaSubKomponen = data[i][1].toString().trim();
      this.currentState = RkaklStateEnum.Account;
    }
    else if (dataCode.length === 3 && isNumeric(dataCode)) {
      // 051
      Util.log("REVERSE IN LENGTH OF COMPONENT");
      this.kodeKomponen = dataCode;
      this.namaKomponen = data[i][1].toString().trim();
      this.kodeBlokir = Resolve.toString(data[i][6], true);
      this.currentState = RkaklStateEnum.SubComponent;
    }
    else if (dataCode.length === 12) {
      // 3996.AEC.002
      Util.log("REVERSE IN LENGTH OF SUB OUTPUT");
      const outputStrCode = dataCode.toString().trim().split('.');
      // this.kodeKegiatan = outputStrCode[0];
      // this.namaKegiatan = data[i][1].trim();
      // this.kodeOutput = outputStrCode[1];
      // this.namaOutput = data[i][1].trim();
      this.kodeSubOutput = outputStrCode[2];
      this.namaSubOutput = data[i][1].toString().trim();
      this.subOutputVolume = Number(data[i][2]);
      this.subOutputSatuan = data[i][3].toString().trim();
      this.currentState = RkaklStateEnum.Component;
    }
    else if (dataCode.length === 8) {
      // 3996.AEC
      Util.log("REVERSE IN LENGTH OF OUTPUT");
      const outputStrCode = dataCode.toString().trim().split('.');
      // this.kodeKegiatan = outputStrCode[0];
      // this.namaKegiatan = data[i][1].trim();
      this.kodeOutput = outputStrCode[1];
      this.namaOutput = data[i][1].toString().replace('[Base Line]', '').trim();
      this.outputVolume = Number(data[i][2]);
      this.outputSatuan = data[i][3].toString().trim();
      this.currentState = RkaklStateEnum.SubOutput;
    }
    else if (dataCode.length === 4 && isNumeric(dataCode)) {
      // 3996
      Util.log("REVERSE IN LENGTH OF ACTIVITY");
      this.kodeKegiatan = dataCode;
      this.namaKegiatan = data[i][1].toString().trim();
      this.currentState = RkaklStateEnum.Output;
    }
    else if (dataCode.length === 9) {
      // 022.12.DL
      Util.log("REVERSE IN LENGTH OF PROGRAM");
      this.kodeProgram = dataCode.replace(/\./g, '');
      this.namaProgram = data[i][1].toString().trim();
      this.currentState = RkaklStateEnum.Activity;
    } else {
      Util.log(`IN REVERSE => ${dataCode} with length : ${dataCode.length}`);
    }

    Util.logEndGroup();
  }

  private async initRowData(subDetail: boolean = false) {
    this.currentRowData.TA = this.ta;
    this.currentRowData.ba = this.pa;
    this.currentRowData.baEs1 = this.paEselon1;
    this.currentRowData.kdSatker = this.paSatker;
    this.currentRowData.kdBagian = this.bagian;
    this.currentRowData.program = this.kodeProgram;
    this.currentRowData.kegiatan = this.kodeKegiatan;
    this.currentRowData.output = this.kodeOutput;
    this.currentRowData.outputVolume = this.outputVolume;
    this.currentRowData.outputSatuan = this.outputSatuan;
    this.currentRowData.subOutput = this.kodeSubOutput;
    this.currentRowData.subOutputVolume = this.subOutputVolume;
    this.currentRowData.subOutputSatuan = this.subOutputSatuan;
    this.currentRowData.komponen = this.kodeKomponen;
    this.currentRowData.subKomponen = this.kodeSubKomponen;
    this.currentRowData.akun = this.kodeAkun;
    this.currentRowData.name = this.namaAkun;
    this.currentRowData.detail = this.detail;
    this.currentRowData.bidang = this.bidang;

    if (subDetail) {
      this.currentRowData.subDetail = this.subDetail;
    }

    this.currentRowData.volume = this.volume;
    this.currentRowData.satuan = this.satuan;
    this.currentRowData.hargaSatuan = this.hargaSatuan;
    this.currentRowData.hargaTotal = this.jumlahHarga;
    this.currentRowData.kewenangan = this.kewenangan;
    this.currentRowData.kodeBlokir = this.kodeBlokir;
    this.currentRowData.sumberDana = this.sumberDana;
    this.currentRowData.lokasi = this.lokasi;
    this.currentRowData.wilayah = this.wilayah;
    this.currentRowData = await RkaklModel.initRelation(this.currentRowData, this.referenceData);
    await this.initIsNewData(this.currentRowData);
    this.serviceData.push(this.currentRowData);
    this.currentRowData = new RkaklModel();
    // this.detail = null;
    this.subDetail = null;
    this.volume = 0;
    this.satuan = null;
    this.hargaSatuan = 0;
    this.jumlahHarga = 0;

    if (this.serviceData.length === 100) {
      const response: ResponseBase = await this.sendProcessLinker();
      if (response.success) {
        this.linkerRevision = response.revision;
        this.linkerIsFirstProcess = false;
        this.linkerIsSuccess = true;
        this.linkerPercent = Math.round(((this.currentRowInSheetData) / this.totalRowInSheetData) * 100);
        this.initProgress(new LinkerProgressModel(false, this.linkerPercent, null));
        this.serviceData = [];
        return response;
      } else {
        return response;
      }
    } else {
      return new ResponseBase();
    }
  }

  private async sendProcessLinker() {
    // console.log(this.serviceData);
    // return new ResponseBase();
    return await this.apiService.postDataServiceAsync(`${environment.base_api}${ApiPath.LINKER_RKAKL}`,
      {data: btoa(JSON.stringify(this.serviceData)),
        firstProcess: this.linkerIsFirstProcess,
        TA: this.ta,
        adkCode: this.adkCode,
        adkDate: this.adkDate,
        adkType: this.linkerType,
        adkRevision: this.linkerRevision,
        adk: 'linker-rkakl-process'})
      .then(data => {
        return data as ResponseBase;
      }).catch(reason => {
        const response = new ResponseBase();
        response.success = false;
        response.message = reason.toString();
        return response;
      });
  }

  private async initIsNewData(currentRowData: RkaklModel) {
    if (currentRowData.mnkRefProgramID === -1) {
      currentRowData.namaProgram = this.namaProgram;
    }

    if (currentRowData.mnkRefKegiatanID === -1) {
      currentRowData.namaKegiatan = this.namaKegiatan;
    }

    if (currentRowData.mnkRefOutputID === -1) {
      currentRowData.namaOutput = this.namaOutput;
    }

    if (currentRowData.mnkRefSubOutputID === -1) {
      currentRowData.namaSubOutput = this.namaSubOutput;
    }

    if (currentRowData.mnkRefKomponenID === -1) {
      currentRowData.namaKomponen = this.namaKomponen;
    }

    if (currentRowData.mnkRefSubKomponenID === -1) {
      currentRowData.namaSubKomponen = this.namaSubKomponen;
    }
  }
}
