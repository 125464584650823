export class LinkerProgressModel {
  isFinished: boolean;
  percent: number;
  message: string;

  constructor(isFinished: boolean, percent: number, message: string) {
    this.isFinished = isFinished;
    this.percent = percent;
    this.message = message;
  }
}
