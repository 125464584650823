import { Component, OnInit } from '@angular/core';
import {ScriptService} from '../../systems/services/script.service';
import {AppsService} from '../../systems/services/apps.service';
import {UserModel} from '../../models/auth';
import {AuthService} from '../../systems/services/auth.service';
import {Router} from '@angular/router';
import {RoleService} from '../../systems/services/role.service';
declare const $: any;

@Component({
  selector: 'app-app-navigation-layout',
  templateUrl: './app-navigation-layout.component.html',
  styleUrls: ['./app-navigation-layout.component.css'],
  providers: [
    ScriptService
  ]
})
export class AppNavigationLayoutComponent implements OnInit {
  userData: UserModel;
  authenticated = false;
  constructor(private route: Router, private scriptService: ScriptService, private appService: AppsService,
              private autService: AuthService, public role: RoleService) {
    this.userData = appService.getUser();
  }

  ngOnInit() {
    setTimeout(() => {
      this.scriptService.load('app', 'switcher', 'scrollbar', 'sparkline', 'wave', 'sidebar').then((data) => {
        this.scriptService.load('custom').then((dateInner) => {
          // console.log('script loaded ', data);
          // console.log('inner script loaded ', dateInner);

          if (this.userData.levelID === 2) {
            // ADMIN
            $('#main-wrapper').AdminSettings({
              Theme: false, // this can be true or false ( true means dark and false means light ),
              Layout: 'vertical',
              LogoBg: 'skin2', // You can change the Value to be skin1/skin2/skin3/skin4/skin5/skin6
              NavbarBg: 'skin2', // You can change the Value to be skin1/skin2/skin3/skin4/skin5/skin6
              SidebarType: 'full', // You can change it full / mini-sidebar / iconbar / overlay
              SidebarColor: 'skin5', // You can change the Value to be skin1/skin2/skin3/skin4/skin5/skin6
              SidebarPosition: true, // it can be true / false ( true means Fixed and false means absolute )
              HeaderPosition: true, // it can be true / false ( true means Fixed and false means absolute )
              BoxedLayout: false, // it can be true / false ( true means Boxed and false means Fluid )
            });
          } else {
            // USER
            $('#main-wrapper').AdminSettings({
              Theme: false, // this can be true or false ( true means dark and false means light ),
              Layout: 'vertical',
              LogoBg: 'skin6', // You can change the Value to be skin1/skin2/skin3/skin4/skin5/skin6
              NavbarBg: 'skin5', // You can change the Value to be skin1/skin2/skin3/skin4/skin5/skin6
              SidebarType: 'mini-sidebar', // You can change it full / mini-sidebar / iconbar / overlay
              SidebarColor: 'skin1', // You can change the Value to be skin1/skin2/skin3/skin4/skin5/skin6
              SidebarPosition: true, // it can be true / false ( true means Fixed and false means absolute )
              HeaderPosition: true, // it can be true / false ( true means Fixed and false means absolute )
              BoxedLayout: false, // it can be true / false ( true means Boxed and false means Fluid )
            });
          }
          $('.preloader').fadeOut();
        });

      }).catch(error => console.log(error));
    });
  }

  onClickSignOut() {
    this.authenticated = false;
    this.autService.logout();
    this.redirectTo('auth');
    // this.appServiceModule.initAnthenticated(false);
    // if (!$('body').hasClass( 'ls-toggle-menu' )) {
    //   $('body').toggleClass('ls-toggle-menu');
    // }
  }

  redirectTo(uri) {
    this.route.navigateByUrl('**', {skipLocationChange: true}).then(() =>
      this.route.navigate([uri]));
  }
}
