import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}
  intercept(request, next) {
    const authService = this.injector.get(AuthService);

    if (authService.loggedIn()) {
      const headers = new HttpHeaders({
        Authorization: `Bearer ${environment.app_key}`,
        'Content-Type': 'application/json',
        'Content-XType-App': `${environment.app_name}`,
        'Content-XType-Package': `${environment.app_package}`,
        'Content-XType-Version': `${environment.app_version}`,
        'Content-XType-BuildNumber': `${environment.app_build_number}`,
        'X-CSRF-TOKEN': authService.getToken()
      });
      request = request.clone({headers});
    }

    return next.handle(request);
  }
}
