import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../systems/services/auth.service';
import {ResponseBase} from '../../systems/base/response-base';
import {UserModel} from '../../models/auth';
import {LevelModel} from '../../models/reference';
import {ToastrService} from 'ngx-toastr';
declare const $: any;
@Component({
  selector: 'app-sign-in-layout',
  templateUrl: './sign-in-layout.component.html',
  styleUrls: ['./sign-in-layout.component.css']
})
export class SignInLayoutComponent implements OnInit, OnDestroy {
  stateIfForgot = false;
  previousUrl: string;
  year = (new Date()).getFullYear();
  get email() { return this.formLogin.get('email'); }
  get password() { return this.formLogin.get('password'); }
  get userEmail() { return this.formForgot.get('userEmail'); }

  // formLogin = new FormGroup({
  //   email: new FormControl('ku@bp3ipjakarta.ac.id', [
  //     Validators.required,
  //     Validators.email
  //   ]),
  //   password: new FormControl('irjenit', [
  //     Validators.required,
  //     Validators.minLength(5)
  //   ])
  // });
  formLogin = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(5)
    ])
  });

  formForgot = new FormGroup({
    userEmail: new FormControl('', [
      Validators.required,
      Validators.email
    ])
  });

  loginSubscriber: any;

  constructor(private renderer: Renderer2, private route: ActivatedRoute, private router: Router, private authService: AuthService,
              private toastr: ToastrService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.previousUrl) {
          this.renderer.removeClass(document.body, this.previousUrl);
        }
        const currentUrl = event.url.split('/');
        const currentUrlSlug = currentUrl[currentUrl.length - 1];
        if (currentUrlSlug) {
          this.renderer.addClass(document.body, currentUrlSlug);
        }
        this.previousUrl = currentUrlSlug;
      }
    });
  }

  ngOnInit() {
    // $('#to-recover').on('click', function() {
    //   $('#loginform').fadeOut();
    //   $('#recoverform').fadeIn();
    // });e
  }

  onSignInSubmit() {
    this.loginSubscriber = this.authService.login(this.formLogin.value.email, this.formLogin.value.password).subscribe(
      (response: ResponseBase) => {
        if (response.success) {
          this.authService.setUser(new UserModel(response.data));
          this.authService.setToken(response.token);
          this.authService.setLevel(response.data.level as LevelModel);
          this.authService.setRole(response.role);

          window.location.href = '/';
        }

        if (!response.success && response.isError) {
          console.log(response.error);
        }

        if (!response.success && !response.isError) {
          console.log(response.message);
        }
      },
      error => {
        console.log(error.statusText);
      });
  }

  onPasswordKeyUp($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.onSignInSubmit();
    }
  }

  onForgotClick(event: any) {
    this.stateIfForgot = true;
  }

  onBackClick($event: MouseEvent) {
    this.stateIfForgot = false;
  }

  onResetSubmit(e: MouseEvent) {
    this.authService.resetPassword(this.formForgot.value.userEmail).subscribe((response: ResponseBase) => {
      if (response.success) {
        this.toastr.success('Please check your email to get a new password.');
      } else {
        this.toastr.error(response.message);
      }
    },
      error => {
        this.toastr.error(error.message);
      });
  }

  ngOnDestroy(): void {
    this.loginSubscriber.unsubscribe();
  }
}
