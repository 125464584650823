import {Component, OnDestroy, OnInit} from '@angular/core';
import {NotifyModel} from '../../models/uis';
import {UiService} from '../../systems/services/ui.service';
import {AppsService} from '../../systems/services/apps.service';
import {UserModel} from '../../models/auth';
import {AuthService} from '../../systems/services/auth.service';
import {Router} from '@angular/router';
import {RoleService} from '../../systems/services/role.service';
declare const $: any;

@Component({
  selector: 'app-app-header-layout',
  templateUrl: './app-header-layout.component.html',
  styleUrls: ['./app-header-layout.component.css']
})
export class AppHeaderLayoutComponent implements OnInit, OnDestroy {
  uiSubscribe: any;
  userData: UserModel;
  minYear = 2019;
  listTa = [];
  listBagian = ['KU', 'GARA', 'SD', 'USAHA'];
  currentTA: number;
  currentBagian: any;
  constructor(private route: Router, private __UI: UiService, private appService: AppsService, private authService: AuthService,
    public role: RoleService) {
    this.userData = appService.getUser();
    this.currentTA = Number(appService.getTA());
    const currentYear = (new Date()).getFullYear();
    for (let i = this.minYear; i <= 1 + currentYear; i++) {
      this.listTa.push(i);
    }
    // this.listBagian.push('KU');
    // this.listBagian.push('GARA');
    // this.listBagian.push('SD');
  }

  ngOnInit() {
    this.uiSubscribe = this.__UI.changeNotify.subscribe((dataNotify: NotifyModel) => {
      let thumb: string = null;
      if (dataNotify.style === 'circle') {
        switch (dataNotify.type) {
          case 'danger':
            thumb = '<img width="40" height="40" style="display: inline-block;" src="assets/img/icons/error-256.png"' +
              ' data-src="assets/img/icons/error-64.png" data-src-retina="assets/img/icons/error-256.png" alt="">';
            break;
          case 'warning':
            thumb = '<img width="40" height="40" style="display: inline-block;" src="assets/img/icons/error-256.png"' +
              ' data-src="assets/img/icons/error-64.png" data-src-retina="assets/img/icons/error-256.png" alt="">';
            break;
          case 'info':
            thumb = '<img width="40" height="40" style="display: inline-block;" src="assets/img/icons/info-256.png"' +
              ' data-src="assets/img/icons/info-64.png" data-src-retina="assets/img/icons/info-256.png" alt="">';
            break;
          case 'success':
            thumb = '<img width="40" height="40" style="display: inline-block;" src="assets/img/icons/ok-256.png"' +
              ' data-src="assets/img/icons/ok-64.png" data-src-retina="assets/img/icons/ok-256.png" alt="">';
            break;
          default:
            thumb = null;
            break;
        }
      }

      $('.page-container').pgNotification({
        style: dataNotify.style,
        title: dataNotify.title,
        message: dataNotify.message,
        position: dataNotify.position,
        timeout: dataNotify.dismissible ? 5000 : 0,
        type: dataNotify.type,
        thumbnail: thumb
      }).show();
    });
  }

  ngOnDestroy(): void {
    this.uiSubscribe.unsubscribe();
  }

  changeCurrentTA(ta: any) {
    this.appService.setTA(ta);
    this.currentTA = ta;

    window.location.href = '/';
  }

  changeCurrentBagian(bagian: any) {
    this.appService.setCurrentBagian(bagian);
    this.currentBagian = bagian;

    window.location.href = '/';
  }

  onClickSignOut() {
    this.authService.logout();
    this.redirectTo('auth');
  }

  redirectTo(uri) {
    this.route.navigateByUrl('**', {skipLocationChange: true}).then(() =>
      this.route.navigate([uri]));
  }
}
