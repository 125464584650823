export enum RkaklStateEnum {
  Program,
  Activity,
  Output,
  SubOutput,
  Component,
  SubComponent,
  Account,
  Detail,
  SubDetail
}
