import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {ApiService} from './api.service';
import {LinkerProgressModel} from '../../models/shared/linker-progress.model';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable()
export class ExcelService {
  storeData: any;
  constructor(private api: ApiService) { }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
  }

  public readFile(path: string = null) {
    this.api.getBlobDataService('assets/lampiran.xlsx', false).subscribe(data => {
        this.readFileData(data);
        //
        //
        //
        // const readFile = new FileReader();
        // const workbook = XLSX.readFile(path);
        // const firstSheetName = workbook.SheetNames[0];
        // const worksheet = workbook.SheetNames[firstSheetName];
        // console.warn(worksheet);
      },
      error => {
        console.log(error);
      });

  }

  readFileData(myBlob) {
    // return new Promise((resolve, reject) => {
      const readFile = new FileReader();

      readFile.onload = async (e) => {
        this.storeData = readFile.result;
        const data = new Uint8Array(this.storeData);
        const arr = [];
        for (let i = 0; i !== data.length; ++i) {
          arr[i] = String.fromCharCode(data[i]);
        }
        const binaryStr = arr.join('');
        const workbook = XLSX.read(binaryStr, {type: 'binary'});
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const dataJson = XLSX.utils.sheet_to_formulae(worksheet);
        // const dataJson = XLSX.utils.sheet_to_json(worksheet, {header: 1});
        console.warn(dataJson);
        // await this.sinkWorksheetData(ta, this.bagian, referenceData);
        // this.initDefaultLinker();
        // resolve(new LinkerProgressModel(true,
        //   (this.currentRowInSheetData / this.totalRowInSheetData ) * 100, null));
      };

      // readFile.onerror = reject;

      readFile.readAsArrayBuffer(myBlob);
    // });




    // const myReader = new FileReader();
    // // handler executed once reading(blob content referenced to a variable) from blob is finished.
    // myReader.addEventListener('loadend', (e: any) => {
    //   console.log(e.srcElement.result); // prints a string
    // });
    // // start the reading process.
    // myReader.readAsText(myBlob);
  }
}
