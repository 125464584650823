export enum ReferencePath {
  MINISTRY_REF = 'reference/pa.json', //
  ECHELON_REF = 'reference/echelon.json', //
  UNIT_REF = 'reference/unit.json', //
  SECTION_REF = 'reference/section.json', //
  PROGRAM_REF = 'reference/program', //
  ACTIVITY_REF = 'reference/activity', //
  OUTPUT_REF = 'reference/output', //
  SUB_OUTPUT_REF = 'reference/sub_output', //
  COMPONENT_REF = 'reference/komponen', //
  SUB_COMPONENT_REF = 'reference/sub_komponen', //
  AKUNLV6_REF = 'reference/akun', //
  AUTHORITY_REF = 'reference/authority.json', //
  FUNDS_REF = 'reference/founds.json', //
  LOCATION_REF = 'reference/location.json', //
  REGION_REF = 'reference/region.json' //

// MINISTRY_REF = 'reference/33734d3c-b46a-401b-8240-b27b647375e8.json', //
//   ECHELON_REF = 'reference/d784c931-7701-4efa-b307-28de706d0ceb.json', //
//   UNIT_REF = 'reference/084f2e4f-39a5-46d7-b55d-36e4cc38d363.json', //
//   BAGIAN_REF = 'reference/e8c18462-dfb9-4e3a-97c1-9f311ee05295.json', //
//   PROGRAM_REF = 'reference/e561a73d-cdb5-4a85-97ca-5019286c9a36.json', //
//   ACTIVITY_REF = 'reference/b93e386f-9b6b-49c0-a94b-46b13d342024.json', //
//   OUTPUT_REF = 'reference/a7086f97-80ae-4041-b09b-24f22a9d3668.json', //
//   SUB_OUTPUT_REF = 'reference/5b1797cb-d28e-460d-8969-1321cfc166e9.json', //
//   COMPONENT_REF = 'reference/00175e0e-5afc-4dd2-85cf-689d864a444d.json', //
//   SUB_COMPONENT_REF = 'reference/f754c90b-e727-4e63-9370-a055ab1c4bbc.json', //
//   AKUNLV6_REF = 'reference/aa0dd20e-732b-4e28-ac66-0b3252c0f5cc.json', //
//   AUTHORITY_REF = 'reference/473e9000-656c-496b-8768-aef6652028bd.json', //
//   FUNDS_REF = 'reference/ee1c4d49-086f-4594-957d-5e05c08964fc.json', //
//   LOCATION_REF = 'reference/31462a07-9b48-46df-b49e-cf693ca218de.json', //
//   REGION_REF = 'reference/4aeecb31-641f-4c94-9cc0-b9097f68cf51.json' //
}
