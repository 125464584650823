export class Resolve {
    public static toString(data: any, nullable: boolean = false): string | null {
        return data !== undefined ? data.toString().trim() : (nullable ? null : '');
    }

    public static toNumber(data: any): number {
        return data !== undefined ? Number(data) : 0;
    }

    public static isExist(data: any): boolean {
        return (data !== undefined && data !== null);
    }
}