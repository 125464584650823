import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import {RoleService} from '../services/role.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private roleService: RoleService) {}

  canActivate(): boolean {
    if (this.authService.loggedIn()) {
      return true;
    } else {
       window.location.href = '/auth';
       return false;
    }
  }

  canAccess(key: string): boolean {
      return this.roleService.getRoleValue(key, 'View');
  }
}
