export class NotifyModel {
  type: string;
  title: string;
  message: string;
  style: string;
  position: string;
  url: string;
  target: string;
  dismissible: boolean;

  constructor(type: string, title: string, message: string, style: string = 'circle', position: string = 'top-right',
              dismissible: boolean = true, url: string = null, target: string = null) {
    this.type = type === '' || type === null ? 'info' : type; // info warning success danger default
    this.title = title === '' || title === null ? null : title;
    this.message = message === '' || message === null ? 'No message' : message;
    this.style = style;
    this.position = position;
    this.dismissible = dismissible;
    this.url = url === '' || url === null ? null : url;
    this.target = target === '' || target === null ? null : target;
  }
}
