import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {ReportingService} from '../../systems/services/reporting.service';
import {ReportingBase} from '../../systems/base/reporting/reporting-base';
import {Subscription} from 'rxjs';
declare const $: any;
@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.css']
})
export class AppLayoutComponent implements OnInit, OnDestroy {
  reportingSubscriber: Subscription;
  dataReporting: ReportingBase;
  showReporting = false;
  constructor(private reportingService: ReportingService) {
    // this.scriptService.load('app', 'app-min', 'switcher', 'scrollbar', 'sparkline', 'wave', 'sidebar', 'custom').then((data) => {
    //   console.log('script loaded ', data);
    // }).catch(error => console.log(error));
  }

  ngOnInit() {

    this.reportingSubscriber = this.reportingService.changeDataReporting.subscribe((dataReporting: ReportingBase) => {
      if (!!dataReporting) {
        this.dataReporting = dataReporting;
        this.showReporting = true;
      } else {
        this.dataReporting = null;
        this.showReporting = false;
      }
    });
    // setTimeout(() => {
    //   $('.preloader').fadeOut();
    //   // $('#main-wrapper').AdminSettings({
    //   //   Theme: false, // this can be true or false ( true means dark and false means light ),
    //   //   Layout: 'vertical',
    //   //   LogoBg: 'skin4', // You can change the Value to be skin1/skin2/skin3/skin4/skin5/skin6
    //   //   NavbarBg: 'skin6', // You can change the Value to be skin1/skin2/skin3/skin4/skin5/skin6
    //   //   SidebarType: 'mini-sidebar', // You can change it full / mini-sidebar / iconbar / overlay
    //   //   SidebarColor: 'skin4', // You can change the Value to be skin1/skin2/skin3/skin4/skin5/skin6
    //   //   SidebarPosition: true, // it can be true / false ( true means Fixed and false means absolute )
    //   //   HeaderPosition: true, // it can be true / false ( true means Fixed and false means absolute )
    //   //   BoxedLayout: false, // it can be true / false ( true means Boxed and false means Fluid )
    //   // });
    // });
  }

  ngOnDestroy(): void {
    this.reportingSubscriber.unsubscribe();
  }

  onRequestHideReporting(e: boolean) {
    this.showReporting = false;
  }
}
