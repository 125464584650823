import {ReportingMode} from '../../enumeration/reporting-mode.enum';
import {ReportingSourceBase} from './reporting-source-base';

export class ReportingBase {
  mode: ReportingMode;
  path: string;
  sources: ReportingSourceBase[];
  forceDownload: boolean;
  format: 'PDF' | 'XLSX' | 'DOCX' | null;

  constructor(mode: ReportingMode, path: string, sources: ReportingSourceBase[], forceDownload: boolean = false, format: 'PDF' | 'XLSX' | 'DOCX' | null = null) {
    this.mode = mode;
    this.path = path;
    this.sources = sources;
    this.forceDownload = forceDownload;
    this.format = format;
  }
}
