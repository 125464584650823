import {EventEmitter, Injectable, Output} from '@angular/core';
import {ReportingBase} from '../base/reporting/reporting-base';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {
  private dataReporting: ReportingBase;

  @Output() changeDataReporting: EventEmitter<ReportingBase> = new EventEmitter();

  initDataReporting(data: ReportingBase) {
    this.dataReporting = data;
    this.changeDataReporting.emit(this.dataReporting);
  }

  constructor() { }
}
