import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {LsPathEnum} from '../enumeration/ls-path.enum';

@Injectable()
export class RoleService {

  constructor() {}

  getRoles(): any {
    const roles = localStorage.getItem(`${environment.base_key}${LsPathEnum.LS_ROLE}`);
    if (roles == null) {
      return null;
    } else {
      let roleData: any;
      roleData = JSON.parse(atob(roles.substring(66)));
      return roleData;
    }
  }

  getRoleValue(key: string, roleAccess: string): boolean {
    const roles = this.getRoles();
    if (roles != null) {
      const role = roles.filter(s => s.RoleKey === key);
      if (role.length > 0) {
        return role[0][roleAccess] === 1;
      }
      return false;
    }

    return false;
  }

}
