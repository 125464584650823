import { Injectable, Output, EventEmitter } from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {LoaderModel, NotifyModel} from '../../models/uis';
import {ResponseBase} from '../base/response-base';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  private dataNotify: NotifyModel;
  private dataLoader: LoaderModel;

  @Output() changeNotify: EventEmitter<NotifyModel> = new EventEmitter();
  @Output() changeLoader: EventEmitter<LoaderModel> = new EventEmitter();

  initDefaultNotify(data: NotifyModel) {
    this.dataNotify = data;
    this.changeNotify.emit(this.dataNotify);
  }

  initLoader(data: LoaderModel) {
    this.dataLoader = data;
    this.changeLoader.emit(this.dataLoader);
  }

  constructor() { }

  initNotify(title: string, message: string, type: string = 'info', position: string = 'top-right', style: string = 'circle') {
    this.initDefaultNotify(new NotifyModel(type, title, `${message}`, style, position, true));
  }

  initErrorResponseNotify(response: ResponseBase, position: string = 'top-right') {
    if (!response.success && !response.error) {
      this.initDefaultNotify(new NotifyModel('warning', null, `${response.message}`, 'circle', position, true));
    } else {
      this.initDefaultNotify(new NotifyModel('danger', null, `${response.error.message}`, 'circle', position, false));
    }
  }

  initRejectResponseNotify(reason: HttpErrorResponse, position: string = 'bottom') {
      this.initDefaultNotify(new NotifyModel('danger', null, `${reason.message}`, 'bar', position, false));
  }

}
