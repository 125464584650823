// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  app_name: 'Monitoring Keuangan BP3IP',
  base_api: 'api/v1/',
  base_key: 'dbg-mnk',
  app_key: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJlLXdvcmtzaG9wLWp3dCIsInN1YiI6IjEiLCJpYXQiOjE1NjMzN' +
    'TYxNzUsImV4cCI6MTYyNjQyODE3NX0.B6P6TRtOcdygKtzrgC29akyzSnctDpnUTxmUkaM-h8k',
  def_TA: (new Date()).getFullYear(),
  // api_url: 'http://192.168.1.10:8184/',
  // api_url: 'http://api.monika.bispro-core.web.local/',
  // api_url: 'http://localhost:8005/',
  api_url: 'http://apimonika.bp3ip.ac.id/',
  // api_url: 'http://143.198.223.12/public/',
  // api_url: 'http://api.monika.bispro-core.web.id/',
  //base_url: 'http://localhost:4200/',
  base_url: 'http://monika.bp3ip.ac.id/',
  // base_url: 'http://143.198.223.12:8080/',
  app_package: 'id.go.dephub.bp3ip.monitoring-keuangan',
  app_version: '1.0.0',
  app_build_number: '1',
  tax: {
    ppn_dn: '411211',
    pph_21: '411121',
    pph_22: '411122',
    pph_23: '411124',
    pph_final: '411128',
    nihil: '815113'
  },
  base_code: {
    piece: '015.04.01',
    piece_nihil: '022.12.01'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
