import {SppDataModel} from './spp-data.model';

export class SppBluDetailModel {
  sppTA: number;
  sppTypeID: number;
  sppType: string;
  sppName: string;
  sppNumber: number;
  sppBa: string;
  sppBaEs1: string;
  sppKdSatker: string;
  sppKdBagian: string;
  sppProgram: string;

  lampiran: string;
  nomorLampiran: string;
  tanggalLampiran: string;

  tanggalSpp: string;
  nomorSpp: string;
  sifatPembayaran: string;
  jenisPembayaran: string;
  departemen: string;
  unitOrganisasi: string;
  satuanKerja: string;
  lokasi: string;
  tempat: string;
  alamat: string;
  kegiatan: string;
  kodeKegiatan: string;
  program: string;
  kewenangan: string;

  tujuan: string;
  lokasiTujuan: string;
  satkerTujuan: string;
  dasar: string;
  jumlahPembayaran: number;
  yangDimintakan: string;
  untukKeperluan: string;
  jenisBelanja: string;
  atasNama: string;
  alamatTujuan: string;
  rekeningPada: string;
  nomorRekening: string;
  nomorSPK: string;
  tanggalSPK: string;
  kontrak: string;
  nilaiSPK: number;
  penjelasan: string;
  realisasi: SppDataModel[];

}
