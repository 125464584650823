interface Scripts {
  name: string;
  src: string;
}
export const ScriptStore: Scripts[] = [
  {name: 'app', src: 'assets/dist/js/app.min.js'},
  {name: 'app-mini', src: 'assets/dist/js/app.init.mini-sidebar.js'},
  {name: 'switcher', src: 'assets/dist/js/app-style-switcher.js'},
  {name: 'scrollbar', src: 'assets/libs/perfect-scrollbar/dist/perfect-scrollbar.jquery.min.js'},
  {name: 'sparkline', src: 'assets/extra-libs/sparkline/sparkline.js'},
  {name: 'wave', src: 'assets/dist/js/waves.js'},
  {name: 'sidebar', src: 'assets/dist/js/sidebarmenu.js'},
  {name: 'custom', src: 'assets/dist/js/custom.js'},
  {name: 'd3', src: 'assets/extra-libs/c3/d3.min.js'},
  {name: 'c3', src: 'assets/extra-libs/c3/c3.min.js'},
];

// {name: 'app', src: '../../../assets/dist/js/app.min.js'},
// {name: 'scrollbar', src: '../../../assets/libs/perfect-scrollbar/dist/perfect-scrollbar.jquery.min.js'},
// {name: 'sparkline', src: '../../../assets/extra-libs/sparkline/sparksline.js'},
// {name: 'sidebar', src: '../../../assets/dist/js/sidebarmenu.js'},
// {name: 'custom', src: '../../../assets/dist/js/custom.min.js'},
