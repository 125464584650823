import {RankModel} from '../reference/rank.model';

export class EmployeeModel {
  IdEmployee: number;
  KdSatker: string;
  EmployeeName: string;
  RankID: number;
  Rank: RankModel;
  EmployeeIdNumber: string;

  constructor(data: any) {
    this.IdEmployee = data.IdEmployee;
    this.KdSatker = data.KdSatker;
    this.EmployeeName = data.EmployeeName;
    this.RankID = data.RankID;
    this.Rank = data.has_rank as RankModel;
    this.EmployeeIdNumber = data.EmployeeIdNumber;
  }
}
