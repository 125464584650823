import {ReferenceDataModel} from '../reference/reference-data.model';

export class SppDataModel {
  IdSpp: string;
  TA: number;
  ba: string;
  baEs1: string;
  kdSatker: string;
  kdBagian: string;
  program: string;
  kegiatan: string;
  output: string;
  subOutput: string;
  komponen: string;
  subKomponen: string;
  akun: string;
  amount: number;
  kewenangan: string;
  sumberDana: string;
  lokasi: string;
  wilayah: string;
  tanggal: string;
  detailName: string;
  // kegiatan: string;
  // output: string;
  // subOutput: string;
  // komponen: string;
  // subKomponen: string;
  // akun: string;
  // pagu: number;
  // spp: number;
  // sisaDana: number;
  mnkRefKementerianID: number;
  mnkRefEs1ID: number;
  mnkRefSatkerID: number;
  mnkRefBagianID: number;
  mnkRefProgramID: number;
  mnkRefKegiatanID: number;
  mnkRefOutputID: number;
  mnkRefSubOutputID: number;
  mnkRefKomponenID: number;
  mnkRefSubKomponenID: number;
  mnkRefAkunLv6ID: number;
  mnkRefKewenanganID: number;
  mnkRefSumberDanaID: number;
  mnkRefLokasiID: number;
  mnkRefWilayahID: number;
  mnkDataSppID: string;

  constructor() {
  }

  public static async initRelation(currentData: SppDataModel, referenceData: ReferenceDataModel) {
    currentData.mnkRefKementerianID = await this._getKementerianID(currentData, currentData.ba, referenceData);
    currentData.mnkRefEs1ID = await this._getEselon1ID(currentData, currentData.baEs1, referenceData);
    currentData.mnkRefSatkerID = await this._getSatkerID(currentData, currentData.kdSatker, referenceData);
    currentData.mnkRefBagianID = -1;
    currentData.mnkRefProgramID = -1;
    currentData.mnkRefKegiatanID = -1;
    currentData.mnkRefOutputID = -1;
    currentData.mnkRefSubOutputID = -1;
    currentData.mnkRefKomponenID = -1;
    currentData.mnkRefSubKomponenID = -1;
    currentData.mnkRefAkunLv6ID = -1;
    // currentData.mnkRefBagianID = await this._getBagianID(currentData, currentData.kdBagian, referenceData);
    // currentData.mnkRefProgramID = await this._getProgramID(currentData, currentData.program, referenceData);
    // currentData.mnkRefKegiatanID = await this._getKegiatanID(currentData, currentData.kegiatan, referenceData);
    // currentData.mnkRefOutputID = await this._getOutputID(currentData, currentData.output, referenceData);
    // currentData.mnkRefSubOutputID = await this._getSubOutputID(currentData, currentData.subOutput, referenceData);
    // currentData.mnkRefKomponenID = await this._getKomponenID(currentData, currentData.komponen, referenceData);
    // currentData.mnkRefSubKomponenID = await this._getSubKomponenID(currentData, currentData.subKomponen, referenceData);
    // currentData.mnkRefAkunLv6ID = await this._getAkunLv6ID(currentData, currentData.akun, referenceData);
    currentData.mnkRefKewenanganID = await this._getKewenanganID(currentData, currentData.kewenangan, referenceData);
    currentData.mnkRefSumberDanaID = await this._getSumberDanaID(currentData, currentData.sumberDana, referenceData);
    currentData.mnkRefLokasiID = await this._getLokasiID(currentData, currentData.lokasi, referenceData);
    currentData.mnkRefWilayahID = await this._getWilayahID(currentData, currentData.lokasi.substring(0, 2), referenceData);
    return currentData;
  }

  private static async _getKementerianID(currentData: SppDataModel, ba: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.KEMENTERIAN_DATA.find( c => c.KodePa === ba);
    return o ? o.IdPa : -1;
  }

  private static async _getEselon1ID(currentData: SppDataModel, baEs1: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.ESELON1_DATA.find( c => c.KodePaEselon1 === baEs1);
    return o ? o.IdPaEselon1 : -1;
  }

  private static async _getSatkerID(currentData: SppDataModel, kdSatker: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.SATKER_DATA.find( c => c.KodePaSatker === kdSatker);
    return o ? o.IdPaSatker : -1;
  }

  private static async _getBagianID(currentData: SppDataModel, bagian: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.BAGIAN_DATA.find( c => c.KodeBagian === bagian);
    return o ? o.IdBagian : -1;
  }

  private static async _getProgramID(currentData: SppDataModel, program: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.PROGRAM_DATA.find( c => c.KodeProgram === program);
    return o ? o.IdProgram : -1;
  }

  private static async _getKegiatanID(currentData: SppDataModel, kegiatan: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.KEGIATAN_DATA.find( c => c.KodeKegiatan === kegiatan && c.ProgramID === currentData.mnkRefProgramID);
    return o ? o.IdKegiatan : -1;
  }

  private static async _getOutputID(currentData: SppDataModel, output: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.OUTPUT_DATA.find( c => c.KodeOutput === output && c.KegiatanID === currentData.mnkRefKegiatanID);
    return o ? o.IdOutput : -1;
  }

  private static async _getSubOutputID(currentData: SppDataModel, subOutput: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.SUB_OUTPUT_DATA.find( c => c.KodeSubOutput === subOutput && c.OutputID === currentData.mnkRefOutputID);
    return o ? o.IdSubOutput : -1;
  }

  private static async _getKomponenID(currentData: SppDataModel, komponen: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.KOMPONEN_DATA.find( c => c.KodeKomponen === komponen && c.SubOutputID === currentData.mnkRefSubOutputID);
    return o ? o.IdKomponen : -1;
  }

  private static async _getSubKomponenID(currentData: SppDataModel, subKomponen: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.SUB_KOMPONEN_DATA.find( c => c.KodeSubKomponen === subKomponen &&
      c.KomponenID === currentData.mnkRefKomponenID);
    return o ? o.IdSubKomponen : -1;
  }

  private static async _getAkunLv6ID(currentData: SppDataModel, akun: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.AKUNLV6_DATA.find( c => c.KodeAkunLv6 === Number(akun));
    return o ? o.IdAkunLv6 : -1;
  }

  private static async _getKewenanganID(currentData: SppDataModel, kewenangan: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.KEWENANGAN_DATA.find( c => c.IdKewenangan === Number(kewenangan));
    return o ? o.IdKewenangan : -1;
  }

  private static async _getSumberDanaID(currentData: SppDataModel, sumberDana: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.SUMBERDANA_DATA.find( c => c.Code === sumberDana);
    return o ? o.IdSumberDana : -1;
  }

  private static async _getLokasiID(currentData: SppDataModel, lokasi: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.LOKASI_DATA.find( c => c.KodeLokasi === lokasi);
    return o ? o.IdLokasi : -1;
  }

  private static async _getWilayahID(currentData: SppDataModel, wilayah: string, referenceData: ReferenceDataModel): Promise<any> {
    const o = referenceData.WILAYAH_DATA.find( c => c.KodeWilyah === wilayah);
    return o ? o.IdWilayah : -1;
  }
}
