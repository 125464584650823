import {EventEmitter, Injectable, Output} from '@angular/core';
import * as XLSX from 'xlsx';
import {WorkBook} from 'xlsx';
import {ReferenceDataModel} from '../../../models/reference/reference-data.model';
import {SppBluState} from '../../enumeration/spp-blu-state.enum';
import {SppBluDetailModel} from '../../../models/data/spp-blu-detail.model';
import {isUndefined} from 'util';
import {SppDataModel} from '../../../models/data/spp-data.model';
import {LinkerProgressModel} from '../../../models/shared/linker-progress.model';
import {ResponseBase} from '../../base/response-base';
import {environment} from '../../../../environments/environment';
import {ApiService} from '../api.service';
import {Util} from '../../helpers/util.helper';
import {DatePipe, formatDate} from '@angular/common';
import {ApiPath} from '../../enumeration/api-path.enum';
import {UserModel} from '../../../models/auth';

@Injectable({
  providedIn: 'root'
})
export class SppBluService {
  private dataProgressLinker: LinkerProgressModel;
  @Output() changeProgressLinker: EventEmitter<LinkerProgressModel> = new EventEmitter();
  sppTypeID: number;
  sppType: string;
  sppName: string;
  storeData: any;
  workbook: WorkBook;
  worksheet: any;
  worksheetRecapitulation: any;
  serviceData: SppBluDetailModel[];
  ta: number;
  pa: string;
  paEselon1: string;
  paSatker: string;
  paBagian: string;
  program: string;
  kewenangan: string;
  sumberDana: string;
  lokasi: string;
  wilayah: string;

  currentState: SppBluState = SppBluState.Attachment;
  currentRowData: SppBluDetailModel = new SppBluDetailModel();

  sppSheetData = [];
  currentSppSheetIndex = 0;
  referenceData: ReferenceDataModel;

  linkerIsFirstProcess = true;
  linkerIsSuccess = false;
  linkerIsError = false;
  linkerPercent = 0;

  datePipe = new DatePipe('id-ID');

  constructor(private apiService: ApiService) {
    this.serviceData = [];
    this.sppTypeID = 1;
    this.sppType = 'BLU';
    this.program = '0221205';
    this.sumberDana = 'F';
    this.kewenangan = '2';
    this.sppName = '0';
  }

  initProgress(data: LinkerProgressModel) {
    this.dataProgressLinker = data;
    this.changeProgressLinker.emit(this.dataProgressLinker);
  }

  public async parseDataBySection(fileToParse: File, ta: number, userData: UserModel, referenceData: ReferenceDataModel) {
    this.referenceData = referenceData;
    this.ta = ta;
    this.pa = userData.satker.KodePa;
    this.paEselon1 = userData.satker.KodePaEselon1;
    this.paSatker = userData.satker.KodePaSatker;
    this.paBagian = userData.bagian === null ? null : userData.bagian.KodeBagian;
    this.lokasi = userData.satker.KodeLokasi;
    this.wilayah = userData.satker.KodeWilayah;
    return new Promise((resolve, reject) => {
      const readFile = new FileReader();

      readFile.onload = async (e) => {
        this.storeData = readFile.result;
        const data = new Uint8Array(this.storeData);
        const arr = [];
        for (let i = 0; i !== data.length; ++i) {
          arr[i] = String.fromCharCode(data[i]);
        }
        const binaryStr = arr.join('');
        this.workbook = XLSX.read(binaryStr, {type: 'binary', cellDates: true, dateNF: 'dd/MM/yyyy;@'});
        await this.sinkWorksheetsData(ta, this.paBagian, referenceData);
        this.linkerPercent = Math.round(((this.currentSppSheetIndex) / this.sppSheetData.length) * 100);
        this.initDefaultLinker();
        resolve(new LinkerProgressModel(true,
          this.linkerPercent, null));
      };

      readFile.onerror = reject;

      readFile.readAsArrayBuffer(fileToParse);
    });
  }

  private initDefaultLinker() {
    this.linkerIsError = false;
    this.linkerIsFirstProcess = true;
    // this.linkerPercent = 0;
    this.serviceData = [];
    this.sppSheetData = [];
  }

  private async sinkWorksheetsData(ta: number, bagian: string, referenceData: ReferenceDataModel) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.workbook.SheetNames.length; i++) {
      const sheetName = this.workbook.SheetNames[i];
      if (sheetName === 'REKAP') {
        this.worksheetRecapitulation = this.workbook.Sheets[sheetName];
      }

      if (sheetName.startsWith('SPP_')) {
        this.sppSheetData.push(sheetName);
      }
    }
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.workbook.SheetNames.length; i++) {
      const sheetName = this.workbook.SheetNames[i];
      // console.log(sheetName);
      if (sheetName.startsWith('SPP_')) {
        this.sppName = sheetName;
        this.currentSppSheetIndex = i + 1;
        this.worksheet = this.workbook.Sheets[sheetName];
        if (ta <= 2020) {
          await this.sinkWorksheetData(ta, bagian, referenceData);
        } else {
          await this.sinkWorksheetDataNew(ta, bagian, referenceData);
        }
      }
    }
  }

  private async sinkWorksheetData(ta: number, bagian: string, referenceData: ReferenceDataModel) {
    const data = XLSX.utils.sheet_to_json(this.worksheet, {header: 0});
    this.currentRowData.sppTA = this.ta;
    this.currentRowData.sppType = this.sppType;
    this.currentRowData.sppTypeID = this.sppTypeID;
    this.currentRowData.sppName = this.sppName;
    this.currentRowData.sppNumber = Number(this.sppName.split('_')[1]);
    this.currentRowData.sppBa = this.pa;
    this.currentRowData.sppBaEs1 = this.paEselon1;
    this.currentRowData.sppKdSatker = this.paSatker;
    this.currentRowData.sppKdBagian = this.paBagian;
    this.currentRowData.sppProgram = this.program;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < data.length; i++) {
      switch (this.currentState) {
        case SppBluState.Attachment:
          await this.initRowAttachment(data[i]);
          break;
        case SppBluState.Header:
          await this.initRowHeader(data[i]);
          break;
        case SppBluState.SubHeader:
          await this.initRowSubHeader(data[i]);
          break;
        case SppBluState.Purpose:
          console.warn(data[i]);
          await this.initRowPurpose(data[i]);
          break;
        case SppBluState.Recapitulation:
          await this.initRowRecapitulation(data[i]);
          break;
        case SppBluState.Data:
          await this.initRowData(data[i]);
          break;
        default:
          break;
      }
    }
    this.serviceData.push(this.currentRowData);
    const responseEnd: ResponseBase = await this.sendProcessLinker();
    if (responseEnd.success) {
      this.linkerIsFirstProcess = false;
      this.linkerIsSuccess = true;
      this.linkerPercent = Math.round(((this.currentSppSheetIndex) / this.sppSheetData.length) * 100);
      this.initProgress(new LinkerProgressModel(true, this.linkerPercent, null));
      this.serviceData = [];
    } else {
      this.initProgress(new LinkerProgressModel(true, this.linkerPercent, responseEnd.message));
    }
    this.currentRowData = new SppBluDetailModel();
    this.currentState = SppBluState.Attachment;
  }

  private async sinkWorksheetDataNew(ta: number, bagian: string, referenceData: ReferenceDataModel) {
    const data = XLSX.utils.sheet_to_json(this.worksheet, {header: 0});
    this.currentRowData.sppTA = this.ta;
    this.currentRowData.sppType = this.sppType;
    this.currentRowData.sppTypeID = this.sppTypeID;
    this.currentRowData.sppName = this.sppName;
    this.currentRowData.sppNumber = Number(this.sppName.split('_')[1]);
    this.currentRowData.sppBa = this.pa;
    this.currentRowData.sppBaEs1 = this.paEselon1;
    this.currentRowData.sppKdSatker = this.paSatker;
    this.currentRowData.sppKdBagian = this.paBagian;
    this.currentRowData.sppProgram = this.program;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < data.length; i++) {
      switch (this.currentState) {
        case SppBluState.Attachment:
          await this.initRowAttachmentNew(data[i]);
          break;
        case SppBluState.Header:
          await this.initRowHeaderNew(data[i]);
          break;
        case SppBluState.SubHeader:
          await this.initRowSubHeaderNew(data[i]);
          break;
        case SppBluState.Purpose:
          console.warn(data[i]);
          await this.initRowPurposeNew(data[i]);
          break;
        case SppBluState.Recapitulation:
          await this.initRowRecapitulation(data[i]);
          break;
        case SppBluState.Data:
          await this.initRowDataNew(data[i]);
          break;
        default:
          break;
      }
    }
    this.serviceData.push(this.currentRowData);
    const responseEnd: ResponseBase = await this.sendProcessLinker();
    if (responseEnd.success) {
      this.linkerIsFirstProcess = false;
      this.linkerIsSuccess = true;
      this.linkerPercent = Math.round(((this.currentSppSheetIndex) / this.sppSheetData.length) * 100);
      this.initProgress(new LinkerProgressModel(true, this.linkerPercent, null));
      this.serviceData = [];
    } else {
      this.initProgress(new LinkerProgressModel(true, this.linkerPercent, responseEnd.message));
    }
    this.currentRowData = new SppBluDetailModel();
    this.currentState = SppBluState.Attachment;
  }

  private async initRowAttachment(data: any) {
    if (!isUndefined(data.__EMPTY_12) && data.__EMPTY_12.toString().trim().startsWith('Lampiran ')) {
      this.currentRowData.lampiran = data.__EMPTY_12.toString().trim();
    }

    if (!isUndefined(data.__EMPTY_12) && data.__EMPTY_12 === 'Nomor') {
      this.currentRowData.nomorLampiran = data.__EMPTY_13.toString().trim().replace(': ', '');
    }

    if (!isUndefined(data.__EMPTY_12) && data.__EMPTY_12 === 'Tanggal') {
      this.currentRowData.tanggalLampiran = data.__EMPTY_13.toString().trim().replace(': ', '');
      this.currentState = SppBluState.Header;
    }
  }

  private async initRowAttachmentNew(data: any) {
    if (!isUndefined(data.__EMPTY_13) && data.__EMPTY_13.toString().trim().startsWith('Lampiran ')) {
      this.currentRowData.lampiran = data.__EMPTY_13.toString().trim();
    }

    if (!isUndefined(data.__EMPTY_13) && data.__EMPTY_13 === 'Nomor') {
      this.currentRowData.nomorLampiran = data.__EMPTY_13.toString().trim().replace(': ', '');
    }

    if (!isUndefined(data.__EMPTY_13) && data.__EMPTY_13 === 'Tanggal') {
      this.currentRowData.tanggalLampiran = data.__EMPTY_13.toString().trim().replace(': ', '');
      this.currentState = SppBluState.Header;
    }
  }

  private async initRowHeader(data: any) {
    if (!isUndefined(data.__EMPTY_8) && data.__EMPTY_8.toString().startsWith('Tanggal')) {
      this.currentRowData.tanggalSpp =
        new Date(Date.parse(data.__EMPTY_9.toString())).toISOString(); // .toLocaleString();
      // console.log(`CEK FORMAT TANGGAL ISO STRING=================>>>> ${new Date(Date.parse(data.__EMPTY_9.toString())).toISOString()}`);
    }

    if (!isUndefined(data.__EMPTY_10) && data.__EMPTY_10.toString().startsWith('Nomor')) {
      this.currentRowData.nomorSpp = isUndefined(data.__EMPTY_11) ? '' : data.__EMPTY_11.toString().trim();
      this.currentRowData.nomorSpp = Util.addPrefix(this.currentRowData.nomorSpp, 5)
        + (isUndefined(data.__EMPTY_12) ? '' : data.__EMPTY_12.toString().trim());
      this.currentState = SppBluState.SubHeader;
    }
  }

  private async initRowHeaderNew(data: any) {
    if (!isUndefined(data.__EMPTY_9) && data.__EMPTY_9.toString().startsWith('Tanggal')) {
      this.currentRowData.tanggalSpp =
        new Date(Date.parse(data.__EMPTY_10.toString())).toISOString(); // .toLocaleString();
      // console.log(`CEK FORMAT TANGGAL ISO STRING=================>>>> ${new Date(Date.parse(data.__EMPTY_9.toString())).toISOString()}`);
    }

    if (!isUndefined(data.__EMPTY_11) && data.__EMPTY_11.toString().startsWith('Nomor')) {
      this.currentRowData.nomorSpp = isUndefined(data.__EMPTY_12) ? '' : data.__EMPTY_12.toString().trim();
      this.currentRowData.nomorSpp = Util.addPrefix(this.currentRowData.nomorSpp, 5)
        + (isUndefined(data.__EMPTY_13) ? '' : data.__EMPTY_13.toString().trim());
      this.currentState = SppBluState.SubHeader;
    }
  }

  private async initRowSubHeader(data: any) {
    // Departemen
    if (!isUndefined(data.__EMPTY_3) && data.__EMPTY_3 === 'Departemen') {
      this.currentRowData.departemen = isUndefined(data.__EMPTY_8) ? '' : data.__EMPTY_8.toString().trim();
    }
    // Sifat Pembayaran
    if (!isUndefined(data.__EMPTY_10) && data.__EMPTY_10.toString().trim() === 'Sifat Pembayaran') {
      this.currentRowData.sifatPembayaran = isUndefined(data.__EMPTY_11) ? '' : data.__EMPTY_11.toString().trim();
    }
    // Jenis Pembayaran
    if (!isUndefined(data.__EMPTY_10) && data.__EMPTY_10.toString().trim() === 'Jenis Pembayaran') {
      this.currentRowData.jenisPembayaran = isUndefined(data.__EMPTY_11) ? '' : data.__EMPTY_11.toString().trim();
    }
    // Kegiatan
    if (!isUndefined(data.__EMPTY_10) && data.__EMPTY_10.toString().startsWith('7. ')) {
      this.currentRowData.kegiatan = isUndefined(data.__EMPTY_12) ? '' : data.__EMPTY_12.toString().trim().replace(': ', '');
    }
    // Unit Organisasi
    if (!isUndefined(data.__EMPTY_3) && data.__EMPTY_3 === 'Unit Organisasi') {
      this.currentRowData.unitOrganisasi = isUndefined(data.__EMPTY_8) ? '' : data.__EMPTY_8.toString().trim();
    }
    // Kode Kegiatan
    if (!isUndefined(data.__EMPTY_12) && data.__EMPTY_12.toString().startsWith('8. ')) {
      this.currentRowData.kodeKegiatan = isUndefined(data.__EMPTY_12) ? '' : data.__EMPTY_12.toString().trim().replace(': ', '');
    }
    // Program
    if (!isUndefined(data.__EMPTY_8) && !isUndefined(data.__EMPTY_10) && data.__EMPTY_10.toString().startsWith('9. ')) {
      this.currentRowData.unitOrganisasi = this.currentRowData.unitOrganisasi + ' ' + data.__EMPTY_8.trim();
      this.currentRowData.program = isUndefined(data.__EMPTY_12) ? '' : data.__EMPTY_12.toString().trim().replace(': ', '');
    }
    // Satuan Kerja
    if (!isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().startsWith('Kantor ')) {
      this.currentRowData.satuanKerja = isUndefined(data.__EMPTY_8) ? '' : data.__EMPTY_8.toString().trim();
    }
    // Kewenangan
    if (!isUndefined(data.__EMPTY_10) && data.__EMPTY_10.toString().startsWith('10. ')) {
      this.currentRowData.kewenangan = isUndefined(data.__EMPTY_12) ? '' : data.__EMPTY_12.toString().trim().replace(': ', '');
    }
    // Lokasi
    if (!isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().startsWith('Lokasi')) {
      this.currentRowData.lokasi = isUndefined(data.__EMPTY_8) ? '' : data.__EMPTY_8.toString().trim();
    }
    // Tempat
    if (!isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().startsWith('Tempat')) {
      this.currentRowData.tempat = isUndefined(data.__EMPTY_8) ? '' : data.__EMPTY_8.toString().trim();
    }
    // Alamat
    if (!isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().startsWith('Alamat')) {
      this.currentRowData.alamat = isUndefined(data.__EMPTY_8) ? '' : data.__EMPTY_8.toString().trim();
    }

    if (isUndefined(data.__EMPTY_3) && !isUndefined(data.__EMPTY_8) && isUndefined(data.__EMPTY_10)) {
      this.currentRowData.alamat = this.currentRowData.alamat + (isUndefined(data.__EMPTY_8) ? '' : ' ' + data.__EMPTY_8.toString().trim());
      this.currentState = SppBluState.Purpose;
    }
  }

  private async initRowSubHeaderNew(data: any) {
    // Departemen
    if (!isUndefined(data.__EMPTY_3) && data.__EMPTY_3 === 'Departemen') {
      this.currentRowData.departemen = isUndefined(data.__EMPTY_9) ? '' : data.__EMPTY_9.toString().trim();
    }
    // Sifat Pembayaran
    if (!isUndefined(data.__EMPTY_11) && data.__EMPTY_11.toString().trim() === 'Sifat Pembayaran') {
      this.currentRowData.sifatPembayaran = isUndefined(data.__EMPTY_12) ? '' : data.__EMPTY_12.toString().trim();
    }
    // Jenis Pembayaran
    if (!isUndefined(data.__EMPTY_11) && data.__EMPTY_11.toString().trim() === 'Jenis Pembayaran') {
      this.currentRowData.jenisPembayaran = isUndefined(data.__EMPTY_12) ? '' : data.__EMPTY_12.toString().trim();
    }
    // Kegiatan
    if (!isUndefined(data.__EMPTY_11) && data.__EMPTY_11.toString().startsWith('7. ')) {
      this.currentRowData.kegiatan = isUndefined(data.__EMPTY_13) ? '' : data.__EMPTY_13.toString().trim().replace(': ', '');
    }
    // Unit Organisasi
    if (!isUndefined(data.__EMPTY_3) && data.__EMPTY_3 === 'Unit Organisasi') {
      this.currentRowData.unitOrganisasi = isUndefined(data.__EMPTY_9) ? '' : data.__EMPTY_9.toString().trim();
    }
    // Kode Kegiatan
    if (!isUndefined(data.__EMPTY_11) && data.__EMPTY_11.toString().startsWith('8. ')) {
      this.currentRowData.kodeKegiatan = isUndefined(data.__EMPTY_13) ? '' : data.__EMPTY_13.toString().trim().replace(': ', '');
    }
    // Program
    if (!isUndefined(data.__EMPTY_9) && !isUndefined(data.__EMPTY_11) && data.__EMPTY_11.toString().startsWith('9. ')) {
      this.currentRowData.unitOrganisasi = this.currentRowData.unitOrganisasi + ' ' + data.__EMPTY_9.trim();
      this.currentRowData.program = isUndefined(data.__EMPTY_13) ? '' : data.__EMPTY_13.toString().trim().replace(': ', '');
    }
    // Satuan Kerja
    if (!isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().startsWith('Kantor ')) {
      this.currentRowData.satuanKerja = isUndefined(data.__EMPTY_9) ? '' : data.__EMPTY_9.toString().trim();
    }
    // Kewenangan
    if (!isUndefined(data.__EMPTY_11) && data.__EMPTY_11.toString().startsWith('10. ')) {
      this.currentRowData.kewenangan = isUndefined(data.__EMPTY_13) ? '' : data.__EMPTY_13.toString().trim().replace(': ', '');
    }
    // Lokasi
    if (!isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().startsWith('Lokasi')) {
      this.currentRowData.lokasi = isUndefined(data.__EMPTY_9) ? '' : data.__EMPTY_9.toString().trim();
    }
    // Tempat
    if (!isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().startsWith('Tempat')) {
      this.currentRowData.tempat = isUndefined(data.__EMPTY_9) ? '' : data.__EMPTY_9.toString().trim();
    }
    // Alamat
    if (!isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().startsWith('Alamat')) {
      this.currentRowData.alamat = isUndefined(data.__EMPTY_9) ? '' : data.__EMPTY_9.toString().trim();
    }

    if (isUndefined(data.__EMPTY_3) && !isUndefined(data.__EMPTY_9) && isUndefined(data.__EMPTY_11)) {
      this.currentRowData.alamat = this.currentRowData.alamat + (isUndefined(data.__EMPTY_9) ? '' : ' ' + data.__EMPTY_9.toString().trim());
      this.currentState = SppBluState.Purpose;
    }
  }

  private async initRowPurpose(data: any) {
    // Yth
    if (!isUndefined(data.__EMPTY_2) && data.__EMPTY_2.toString().trim().startsWith('Yth')) {
      this.currentRowData.tujuan = isUndefined(data.__EMPTY_3) ? '' : data.__EMPTY_3.toString().trim();
    }
    // Satker
    if (isUndefined(data.__EMPTY_1) && !isUndefined(data.__EMPTY_2) && isUndefined(data.__EMPTY_9)) {
      this.currentRowData.satkerTujuan = isUndefined(data.__EMPTY_3) ? '' : data.__EMPTY_3.toString().trim();
    }
    // Lokasi Tujuan
    if (isUndefined(data.__EMPTY_1) && !isUndefined(data.__EMPTY_2) && data.__EMPTY_2.toString().trim().startsWith('di ')) {
      this.currentRowData.lokasiTujuan = isUndefined(data.__EMPTY_2) ? '' : data.__EMPTY_2.toString().trim().replace('di ');
    }
    // Dasar
    if (!isUndefined(data.__EMPTY_2) && data.__EMPTY_2.toString().trim().startsWith('Berdasarkan')) {
      this.currentRowData.dasar = isUndefined(data.__EMPTY_2) ? '' : data.__EMPTY_2.toString().trim();
    }
    // Jumlah Pembayaran
    if (!isUndefined(data.__EMPTY_2) && data.__EMPTY_2.toString().trim().startsWith('1.')
      && !isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().trim().startsWith('Jumlah ')) {
      this.currentRowData.jumlahPembayaran = isUndefined(data.__EMPTY_9) ? 0 : Number(data.__EMPTY_9);
    }
    // Yang Dimintakan
    if (!isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().trim().startsWith('yang dimintakan')) {
      this.currentRowData.yangDimintakan = isUndefined(data.__EMPTY_9) ? '' : data.__EMPTY_9.toString().trim();
    }
    // Untuk Keperluan
    if (!isUndefined(data.__EMPTY_2) && data.__EMPTY_2.toString().trim().startsWith('2.')
      && !isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().trim().startsWith('Untuk Keperluan')) {
      this.currentRowData.untukKeperluan = isUndefined(data.__EMPTY_9) ? '' : data.__EMPTY_9.toString().trim();
    }
    // Jenis Belanja
    if (!isUndefined(data.__EMPTY_2) && data.__EMPTY_2.toString().trim().startsWith('3.')
      && !isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().trim().startsWith('Jenis Belanja')) {
      this.currentRowData.jenisBelanja = isUndefined(data.__EMPTY_9) ? '' : data.__EMPTY_9.toString().trim();
    }
    // Atas Nama
    if (!isUndefined(data.__EMPTY_2) && data.__EMPTY_2.toString().trim().startsWith('4.')
      && !isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().trim().startsWith('Atas Nama')) {
      this.currentRowData.atasNama = isUndefined(data.__EMPTY_9) ? '' : data.__EMPTY_9.toString().trim();
    }
    // Alamat
    if (!isUndefined(data.__EMPTY_2) && data.__EMPTY_2.toString().trim().startsWith('5.')
      && !isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().trim().startsWith('Alamat')) {
      this.currentRowData.alamatTujuan = isUndefined(data.__EMPTY_9) ? '' : data.__EMPTY_9.toString().trim();
    }
    // Rekening pada
    if (!isUndefined(data.__EMPTY_2) && data.__EMPTY_2.toString().trim().startsWith('6.')
      && !isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().trim().startsWith('Mempunyai ')) {
      this.currentRowData.rekeningPada = isUndefined(data.__EMPTY_9) ? '' : data.__EMPTY_9.toString().trim();
    }
    // Nomor Rekening
    if (!isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().trim().startsWith('Nomor Rekening')) {
      this.currentRowData.nomorRekening = isUndefined(data.__EMPTY_9) ? '' : data.__EMPTY_9.toString().trim();
    }
    // Nomor dan Tnaggal SPK
    if (!isUndefined(data.__EMPTY_2) && data.__EMPTY_2.toString().trim().startsWith('7.')
      && !isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().trim().startsWith('Nomor dan Tanggal ')) {
      this.currentRowData.nomorSPK = isUndefined(data.__EMPTY_9) ? '' : data.__EMPTY_9.toString().trim();
    }
    // Kontrak
    if (!isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().trim().startsWith('Kontrak')) {
      this.currentRowData.kontrak = isUndefined(data.__EMPTY_9) ? '' : data.__EMPTY_9.toString().trim();
    }
    // NIlai SPK
    if (!isUndefined(data.__EMPTY_2) && data.__EMPTY_2.toString().trim().startsWith('8.')
      && !isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().trim().startsWith('Nilai SPK, ')) {
      this.currentRowData.nilaiSPK = isUndefined(data.__EMPTY_9) ? '' : data.__EMPTY_9.toString().trim();
    }
    // Dengan Penjelasan
    if (!isUndefined(data.__EMPTY_2) && data.__EMPTY_2.toString().trim().startsWith('9.')
      && !isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().trim().startsWith('Dengan Penjelasan')) {
      this.currentRowData.penjelasan = isUndefined(data.__EMPTY_9) ? '' : data.__EMPTY_9.toString().trim();
      this.currentState = SppBluState.Recapitulation;
    }
  }

  private async initRowPurposeNew(data: any) {
    // Yth
    if (!isUndefined(data.__EMPTY_2) && data.__EMPTY_2.toString().trim().startsWith('Yth')) {
      this.currentRowData.tujuan = isUndefined(data.__EMPTY_3) ? '' : data.__EMPTY_3.toString().trim();
    }
    // Satker
    if (isUndefined(data.__EMPTY_1) && !isUndefined(data.__EMPTY_2) && isUndefined(data.__EMPTY_9)) {
      this.currentRowData.satkerTujuan = isUndefined(data.__EMPTY_3) ? '' : data.__EMPTY_3.toString().trim();
    }
    // Lokasi Tujuan
    if (isUndefined(data.__EMPTY_1) && !isUndefined(data.__EMPTY_2) && data.__EMPTY_2.toString().trim().startsWith('di ')) {
      this.currentRowData.lokasiTujuan = isUndefined(data.__EMPTY_2) ? '' : data.__EMPTY_2.toString().trim().replace('di ');
    }
    // Dasar
    if (!isUndefined(data.__EMPTY_2) && data.__EMPTY_2.toString().trim().startsWith('Berdasarkan')) {
      this.currentRowData.dasar = isUndefined(data.__EMPTY_2) ? '' : data.__EMPTY_2.toString().trim();
    }
    // Jumlah Pembayaran
    if (!isUndefined(data.__EMPTY_2) && data.__EMPTY_2.toString().trim().startsWith('1.')
      && !isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().trim().startsWith('Jumlah ')) {
      this.currentRowData.jumlahPembayaran = isUndefined(data.__EMPTY_10) ? 0 : Number(data.__EMPTY_10);
    }
    // Yang Dimintakan
    if (!isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().trim().startsWith('yang dimintakan')) {
      this.currentRowData.yangDimintakan = isUndefined(data.__EMPTY_10) ? '' : data.__EMPTY_10.toString().trim();
    }
    // Untuk Keperluan
    if (!isUndefined(data.__EMPTY_2) && data.__EMPTY_2.toString().trim().startsWith('2.')
      && !isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().trim().startsWith('Untuk Keperluan')) {
      this.currentRowData.untukKeperluan = isUndefined(data.__EMPTY_10) ? '' : data.__EMPTY_10.toString().trim();
    }
    // Jenis Belanja
    if (!isUndefined(data.__EMPTY_2) && data.__EMPTY_2.toString().trim().startsWith('3.')
      && !isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().trim().startsWith('Jenis Belanja')) {
      this.currentRowData.jenisBelanja = isUndefined(data.__EMPTY_10) ? '' : data.__EMPTY_10.toString().trim();
    }
    // Atas Nama
    if (!isUndefined(data.__EMPTY_2) && data.__EMPTY_2.toString().trim().startsWith('4.')
      && !isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().trim().startsWith('Atas Nama')) {
      this.currentRowData.atasNama = isUndefined(data.__EMPTY_10) ? '' : data.__EMPTY_10.toString().trim();
    }
    // Alamat
    if (!isUndefined(data.__EMPTY_2) && data.__EMPTY_2.toString().trim().startsWith('5.')
      && !isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().trim().startsWith('Alamat')) {
      this.currentRowData.alamatTujuan = isUndefined(data.__EMPTY_10) ? '' : data.__EMPTY_10.toString().trim();
    }
    // Rekening pada
    if (!isUndefined(data.__EMPTY_2) && data.__EMPTY_2.toString().trim().startsWith('6.')
      && !isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().trim().startsWith('Mempunyai ')) {
      this.currentRowData.rekeningPada = isUndefined(data.__EMPTY_10) ? '' : data.__EMPTY_10.toString().trim();
    }
    // Nomor Rekening
    if (!isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().trim().startsWith('Nomor Rekening')) {
      this.currentRowData.nomorRekening = isUndefined(data.__EMPTY_10) ? '' : data.__EMPTY_10.toString().trim();
    }
    // Nomor dan Tnaggal SPK
    if (!isUndefined(data.__EMPTY_2) && data.__EMPTY_2.toString().trim().startsWith('7.')
      && !isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().trim().startsWith('Nomor dan Tanggal ')) {
      this.currentRowData.nomorSPK = isUndefined(data.__EMPTY_10) ? '' : data.__EMPTY_10.toString().trim();
    }
    // Kontrak
    if (!isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().trim().startsWith('Kontrak')) {
      this.currentRowData.kontrak = isUndefined(data.__EMPTY_10) ? '' : data.__EMPTY_10.toString().trim();
    }
    // NIlai SPK
    if (!isUndefined(data.__EMPTY_2) && data.__EMPTY_2.toString().trim().startsWith('8.')
      && !isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().trim().startsWith('Nilai SPK, ')) {
      this.currentRowData.nilaiSPK = isUndefined(data.__EMPTY_10) ? '' : data.__EMPTY_10.toString().trim();
    }
    // Dengan Penjelasan
    if (!isUndefined(data.__EMPTY_2) && data.__EMPTY_2.toString().trim().startsWith('9.')
      && !isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().trim().startsWith('Dengan Penjelasan')) {
      this.currentRowData.penjelasan = isUndefined(data.__EMPTY_10) ? '' : data.__EMPTY_10.toString().trim();
      this.currentState = SppBluState.Recapitulation;
    }
  }

  private async initRowRecapitulation(data: any) {
    if (!isUndefined(data.__EMPTY_2) && data.__EMPTY_2.toString().trim() === 'II.'
      && !isUndefined(data.__EMPTY_3) && data.__EMPTY_3.toString().trim() === 'SEMUA KEGIATAN') {
      // console.log('REKAPITULATION');
      this.currentRowData.realisasi = [];
      this.currentState = SppBluState.Data;
    }
  }

  private async initRowData(data: any) {
    // console.log(data);
    if (!isUndefined(data.__EMPTY_3) && !isUndefined(data.__EMPTY_4)
      // && !isUndefined(data.__EMPTY_6)
      && !isUndefined(data.__EMPTY_7)
      && !isUndefined(data.__EMPTY_8) && !isUndefined(data.__EMPTY_11) && Number(data.__EMPTY_11) !== 0) {

      let sppData = new SppDataModel();
      sppData.TA = this.ta;
      sppData.ba = this.pa;
      sppData.baEs1 = this.paEselon1;
      sppData.kdSatker = this.paSatker;
      sppData.kdBagian = this.paBagian;
      sppData.program = this.program;
      sppData.kegiatan = isUndefined(data.__EMPTY_3) ? '-' : data.__EMPTY_3.toString().trim();
      sppData.output = isUndefined(data.__EMPTY_4) ? '-' : Util.addPrefix(data.__EMPTY_4.toString().trim());
      if (sppData.output !== '001' && isUndefined(data.__EMPTY_6)) {
        sppData.subOutput = '-';
        sppData.komponen = isUndefined(data.__EMPTY_5) ? '-' : Util.addPrefix(data.__EMPTY_5.toString().trim());
      } else {
        sppData.subOutput = isUndefined(data.__EMPTY_5) ? '-' : Util.addPrefix(data.__EMPTY_5.toString().trim());
        sppData.komponen = isUndefined(data.__EMPTY_6) ? '-' : Util.addPrefix(data.__EMPTY_6.toString().trim());
      }

      sppData.subKomponen = isUndefined(data.__EMPTY_7) ? '-' : data.__EMPTY_7.toString().trim();
      sppData.akun = isUndefined(data.__EMPTY_8) ? '-' : data.__EMPTY_8.toString().trim();
      // sppData.amount = isUndefined(data.__EMPTY_9) ? 0 : Number(data.__EMPTY_9);
      sppData.amount = isUndefined(data.__EMPTY_11) ? 0 : Number(data.__EMPTY_11);
      sppData.kewenangan = this.kewenangan;
      sppData.sumberDana = this.sumberDana;
      sppData.lokasi = this.lokasi;
      sppData.wilayah = this.wilayah;
      sppData.tanggal = this.currentRowData.tanggalSpp;
      // sppData.sisaDana = isUndefined(data.__EMPTY_13) ? 0 : Number(data.__EMPTY_13);
      sppData = await SppDataModel.initRelation(sppData, this.referenceData);
      this.currentRowData.realisasi.push(sppData);
    }
  }

  private async initRowDataNew(data: any) {
    // console.log(data);
    if (!isUndefined(data.__EMPTY_3) && !isUndefined(data.__EMPTY_4)
      && !isUndefined(data.__EMPTY_5) // OUTPUT
      // && !isUndefined(data.__EMPTY_6) // SUB OUTPUT
      && !isUndefined(data.__EMPTY_7) // KOMPONEN
      // && !isUndefined(data.__EMPTY_8) // SUB KOMPONEN
      && !isUndefined(data.__EMPTY_9) // AKUN
      && !isUndefined(data.__EMPTY_12) && Number(data.__EMPTY_12) !== 0) {

      let sppData = new SppDataModel();
      sppData.TA = this.ta;
      sppData.ba = this.pa;
      sppData.baEs1 = this.paEselon1;
      sppData.kdSatker = this.paSatker;
      sppData.kdBagian = this.paBagian;
      this.currentRowData.program = isUndefined(data.__EMPTY_3) ? '-' : data.__EMPTY_3.toString().trim();
      sppData.program = isUndefined(data.__EMPTY_3) ? '-' : data.__EMPTY_3.toString().trim();
      this.currentRowData.kegiatan = isUndefined(data.__EMPTY_4) ? '-' : data.__EMPTY_4.toString().trim();
      sppData.kegiatan = isUndefined(data.__EMPTY_4) ? '-' : data.__EMPTY_4.toString().trim();
      sppData.output = isUndefined(data.__EMPTY_5) ? '-' : Util.addPrefix(data.__EMPTY_5.toString().trim());
      sppData.subOutput = isUndefined(data.__EMPTY_6) ? '-' : Util.addPrefix(data.__EMPTY_6.toString().trim());
      sppData.komponen = isUndefined(data.__EMPTY_7) ? '-' : Util.addPrefix(data.__EMPTY_7.toString().trim());
      sppData.subKomponen = isUndefined(data.__EMPTY_8) ? '-' : data.__EMPTY_8.toString().trim();
      sppData.akun = isUndefined(data.__EMPTY_9) ? '-' : data.__EMPTY_9.toString().trim();
      sppData.amount = isUndefined(data.__EMPTY_12) ? 0 : Number(data.__EMPTY_12);
      sppData.kewenangan = this.kewenangan;
      sppData.sumberDana = this.sumberDana;
      sppData.lokasi = this.lokasi;
      sppData.wilayah = this.wilayah;
      sppData.tanggal = this.currentRowData.tanggalSpp;
      sppData = await SppDataModel.initRelation(sppData, this.referenceData);
      this.currentRowData.realisasi.push(sppData);
    }
  }

  private async sendProcessLinker() {
    // console.warn(this.currentRowData);
    // return new  ResponseBase();
    return await this.apiService.postDataServiceAsync(`${environment.base_api}${ApiPath.LINKER_SPP_BLU}`,
      {
        data: btoa(JSON.stringify(this.currentRowData)),
        firstProcess: this.linkerIsFirstProcess,
        TA: this.ta, adk: 'linker-spp-blu-process'})
      .then(data => {
        return data as ResponseBase;
      }).catch(reason => {
        const response = new ResponseBase();
        response.success = false;
        response.message = reason.toString();
        return response;
      });
  }
}
