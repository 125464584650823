import {PaginationBase} from './pagination-base';
import {ErrorBase} from './error-base';

export class ResponseBase {
  success: boolean;
  token: string;
  total: number;
  totalPages: number;
  message: string;
  isValidationError: boolean;
  validationMessages: string[];
  isError: boolean;
  error: ErrorBase;
  data: any;
  pagination: PaginationBase;
  dataExpands: any;
  dataSelections: [];
  revision: number;
  lastDay: number;
  customData: any;
  role: any;
}
