import {environment} from "../../../environments/environment";

export class Util {
  public static makeRandString(stringLength: number) {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < stringLength; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  public static makeRandNumber(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  public static makeCustomBtoa(data: string, ta: number) {
    const range = this.makeRandNumber(11, 99);
    const prefix = this.makeRandString(range);
    const result = prefix + btoa(data);
    return {
      moduleID: range,
      tokenizer: result,
      TA: ta
    };
  }

  public static resolveCustomBtoa(data: string, range: number) {
    return atob(data.substring(range));
  }

  public static isFixNumber(value: number) {
    return value.toString().split('.').length <= 1;
  }

  public static addPrefix(value: string, total: number = 3) {
    const length = value.length;
    if (length < total) {
      value = '0' + value;
      return this.addPrefix(value, total);
    }
    return value;
  }

  public static addSpaceSuffix(value: string, total: number = 3) {
    const length = value.length;
    // console.warn(`${length} ____ ${value}`);
    if (length < total) {
      value = value + ' ';
      return this.addSpaceSuffix(value, total);
    }
    return value;
  }

  public static clearZeroPrefix(value: string): number {
    if (value !== null) {
      if (value.charAt(0) === '0') {
        value = value.substring(1);
        return Util.clearZeroPrefix(value);
      } else {
        return +value;
      }
    } else {
      return 0;
    }
  }

  public static toRomanChar(value: number): string {
    switch (value) {
      case 1:
        return 'I';
      case 2:
        return 'II';
      case 3:
        return 'III';
      case 4:
        return 'IV';
      case 5:
        return 'V';
      case 6:
        return 'VI';
      case 7:
        return 'VII';
      case 8:
        return 'VIII';
      case 9:
        return 'IX';
      case 10:
        return 'X';
      case 11:
        return 'XI';
      case 12:
        return 'XII';
      default:
        return value.toString();
    }
  }

  public static toIdDetailDateRange(start: string, end: string): string {
    return Util.toIdDetailDate(start) + ' s/d ' + Util.toIdDetailDate(end);
  }

  public static toIdDetailDate(value: string): string {
    let valueSp = value.split('-');
    let day = valueSp[2];
    let month = valueSp[1];
    let year = valueSp[0];

    switch (month) {
      case '01':
        return day + ' Januari ' + year;
      case '02':
        return day + ' Februari ' + year;
      case '03':
        return day + ' Maret ' + year;
      case '04':
        return day + ' April ' + year;
      case '05':
        return day + ' Mei ' + year;
      case '06':
        return day + ' Juni ' + year;
      case '07':
        return day + ' Juli ' + year;
      case '08':
        return day + ' Agustus ' + year;
      case '09':
        return day + ' September ' + year;
      case '10':
        return day + ' Oktober ' + year;
      case '11':
        return day + ' November ' + year;
      case '12':
        return day + ' Desember ' + year;
      default:
        return value;
    }
  }

  public static getRandomRangeInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  public static logGroup(title: any, ...optional: any[]) {
    if (!environment.production) {
      console.group(title, optional);
    }
  }

  public static log(message: any, ...optional: any[]) {
    if (!environment.production) {
      console.log(message, optional);
    }
  }

  public static logEndGroup() {
    if (!environment.production) {
      console.groupEnd();
    }
  }

}
