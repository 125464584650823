import {Injectable} from '@angular/core';
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ResponseBase} from '../base/response-base';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import {Subject, throwError} from 'rxjs';
import {LoadOptions} from 'devextreme/data/load_options';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient) {
  }

  getDataService(arg: any, api: boolean = false) {
    // console.warn(arg);
    if (api) {
      return this.httpClient.get(`${environment.api_url}${environment.base_api}${arg}`);
    } else {
      return this.httpClient.get(`${environment.api_url}${arg}`);
    }
  }

  getBlobDataService(arg: any, api: boolean = false) {
    // console.warn(arg);
    if (api) {
      return this.httpClient.get(`${environment.api_url}${environment.base_api}${arg}`, {responseType: 'blob'});
    } else {
      return this.httpClient.get(`${arg}`, {responseType: 'blob'});
    }
  }

  postDataService(arg: any, data: any, api: boolean = false) {
    if (api) {
      return this.httpClient.post<any>(`${environment.api_url}${environment.base_api}${arg}`, data);
    } else {
      return this.httpClient.post<any>(`${environment.api_url}${arg}`, data);
    }
    // return this.httpClient.post<any>(environment.api_url + arg, data);
  }

  putDataService(arg: any, data: any, api: boolean = false) {
    if (api) {
      return this.httpClient.put<any>(`${environment.api_url}${environment.base_api}${arg}`, data);
    } else {
      return this.httpClient.put<any>(`${environment.api_url}${arg}`, data);
    }
  }

  async getDataServiceAsync(arg: any, api: boolean = false) {
    if (api) {
      return await this.httpClient.get<any>(`${environment.api_url}${environment.base_api}${arg}`)
          .toPromise() as ResponseBase;
    } else {
      return await this.httpClient.get<any>(`${environment.api_url}${arg}`)
          .toPromise() as ResponseBase;
    }
  }

  async putDataServiceAsync(arg: any, data: any, api: boolean = false) {
    if (api) {
      return await this.httpClient
        .put<any>(`${environment.api_url}${environment.base_api}${arg}`, data).toPromise() as ResponseBase;
    } else {
      return await this.httpClient
        .put<any>(environment.api_url + arg, data).toPromise() as ResponseBase;
    }
  }

  async postDataServiceAsync(arg: any, data: any, api: boolean = false) {
    if (api) {
      return await this.httpClient
        .post<any>(environment.api_url + environment.base_api + arg, data).toPromise() as ResponseBase;
    } else {
      return await this.httpClient
        .post<any>(environment.api_url + arg, data).toPromise() as ResponseBase;
    }
  }

  customStoreBySegmentDataService(arg: any, initKeyField: any, userData = {}) {
    return new CustomStore({
      key: initKeyField,
      load: (loadOptions: LoadOptions) => {
        loadOptions.userData = userData;
        return this._sendRequestBySegmentCustomStore(arg, 'GET', loadOptions)
          .toPromise()
          .then((data: any) => {
            return {
              data: data.body.data,
              totalCount: data.body.totalCount
            };
          })
          .catch(error => { throw new Error('Data Loading Error'); });
      },
      byKey: (key: any) => {
        return this.getDataService(arg + '/' + encodeURIComponent(key))
          .toPromise()
          .then((data: any) => {
            return {
              success: data.success,
              data: data.data,
              message: data.message
            };
          })
          .catch(error => { throw new Error('Data ByKey Error'); });
      },
      insert: (dataForm: any) => {
        return this._sendRequestBySegmentCustomStore(arg, 'POST', dataForm)
          .toPromise()
          .then((data: any) => {
            return {
              success: data.success,
              message: data.message
            };
          })
          .catch(error => { throw new Error('Data Insert Error'); });
      },
      update: (key: any, values: any) => {
        // console.log(key);
        // console.log(values);
        // console.log(initKeyField);
        return this._sendRequestBySegmentCustomStore(arg, 'PUT', values, key)
          .toPromise()
          .then((data: any) => {
            return {
              success: data.success,
              message: data.message,
              key: data.data
            };
          })
          .catch(error => { throw new Error('Data Update Error'); });
      },
      remove: (key) => {
        return this._sendRequestCustomStore(arg, 'DELETE', {id: key}, key)
          .toPromise()
          .then((data: any) => {
            return {
              success: data.success,
              message: data.message
            };
          })
          .catch(error => { throw new Error('Data Remove Error'); });
      }
    });
    // return new CustomStore({
    //   key: initKeyField,
    //   load: (loadOptions: any) => {
    //     return this._sendRequestBySegmentCustomStore(arg, 'GET', loadOptions)
    //       .toPromise()
    //       .then((data: any) => {
    //         return {
    //           data: data.body.data,
    //           totalCount: data.body.totalCount
    //         };
    //       })
    //       .catch(error => { throw new Error('Data Loading Error'); });
    //   },
    //   byKey: (key: any) => {
    //     return this.getDataService(arg + '/' + encodeURIComponent(key))
    //       .toPromise()
    //       .then((data: any) => {
    //         return {
    //           success: data.success,
    //           data: data.data,
    //           message: data.message
    //         };
    //       })
    //       .catch(error => { throw new Error('Data ByKey Error'); });
    //   },
    //   insert: (dataForm: any) => {
    //     return this._sendRequestBySegmentCustomStore(arg, 'POST', dataForm)
    //       .toPromise()
    //       .then((data: any) => {
    //         return {
    //           success: data.success,
    //           message: data.message
    //         };
    //       })
    //       .catch(error => { throw new Error('Data Insert Error'); });
    //   },
    //   update: (key: any, values: any) => {
    //     // console.log(key);
    //     // console.log(values);
    //     // console.log(initKeyField);
    //     return this._sendRequestBySegmentCustomStore(arg, 'PUT', values, key)
    //       .toPromise()
    //       .then((data: any) => {
    //         return {
    //           success: data.success,
    //           message: data.message,
    //           key: data.data
    //         };
    //       })
    //       .catch(error => { throw new Error('Data Update Error'); });
    //   },
    //   remove: (key) => {
    //     return this._sendRequestCustomStore(arg, 'DELETE', {id: key}, key)
    //       .toPromise()
    //       .then((data: any) => {
    //         return {
    //           success: data.success,
    //           message: data.message
    //         };
    //       })
    //       .catch(error => { throw new Error('Data Remove Error'); });
    //   }
    // });
  }

  _sendRequestBySegmentCustomStore(arg: any, methodData: any, params: any, key?: any) {
    // console.warn(JSON.stringify(params));
    const dataParam = {
      _request: btoa(JSON.stringify(params)) // .replace('=', 'SM__').replace('+', 'PL__')
    };
    const requestSettings = {
      url: environment.api_url + arg,
      method: methodData,
      data: dataParam,
      contentType: 'application/json; charset=utf-8'
    };

    if (methodData === 'POST') {
      requestSettings.url = requestSettings.url + '/insert/' + key;
    } else if (methodData === 'PUT') {
      requestSettings.url = requestSettings.url + '/update/' + key;
    } else if (methodData === 'DELETE') {
      requestSettings.contentType = 'application/x-www-form-urlencoded; charset=utf-8';
      requestSettings.url = requestSettings.url + '/delete/' + key;
    } else if ( methodData === 'GET') {
      requestSettings.url = requestSettings.url + '?_request=' + requestSettings.data._request;
    }

    return this.httpClient.request(
      new HttpRequest(requestSettings.method, requestSettings.url, requestSettings.data,
        {headers: new HttpHeaders('Content-Type:' + requestSettings.contentType)}));
  }

  customStoreDataService(arg: any, method: any = 'GET', api: boolean = true, initKeyField: any = 'ID', defaultFilter = null) {
    return new CustomStore({
      key: initKeyField,
      load: (loadOptions: any) => {
        return this._sendRequestCustomStore(api, arg, 'GET', loadOptions)
          .toPromise()
          .then((data: any) => {
            return {
              data: data.body.data,
              totalCount: data.body.totalCount
            };
          })
          .catch(error => { throw new Error('Data Loading Error'); });
      },
      byKey: (key: any) => {
        return this.getDataService(arg + `&${initKeyField}=` + encodeURIComponent(key))
          .toPromise()
          .then((data: any) => {
            return {
              success: data.success,
              data: data.data,
              message: data.message
            };
          })
          .catch(error => { throw new Error('Data ByKey Error'); });
      },
      insert: (dataForm: any) => {
        return this._sendRequestCustomStore(api, arg, 'POST', dataForm)
          .toPromise()
          .then((data: any) => {
              return {
                success: data.success,
                message: data.message
              };
          })
          // .catch(this.handleError);
          .catch(error => { throw new Error('Data Insert Error'); });
      },
      update: (key: any, values: any) => {
        console.log(values);
        const urlParameters = Object.entries(values).map(e => e.join('=')).join('&');
        return this._sendRequestCustomStore(api, arg, 'PUT', values, key)
          .toPromise()
          .then((data: any) => {
            return {
              success: data.success,
              message: data.message
            };
          })
          .catch(error => { throw new Error('Data Update Error'); });
      },
      remove: (key) => {
        return this._sendRequestCustomStore(api, arg, 'DELETE', {id: key}, key)
          .toPromise()
          .then((data: any) => {
            return {
              success: data.success,
              message: data.message
            };
          })
          .catch(error => { throw new Error('Data Remove Error'); });
      }
    });
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  _sendRequestCustomStore(api: boolean, arg: any, methodData: any, params: any, key?: any) {
    const dataParam = {
      _request: btoa(JSON.stringify(params))
    };
    const requestSettings = {
      url: api ? environment.api_url + arg : environment.base_url + arg,
      method: methodData,
      data: dataParam,
      contentType: 'application/json; charset=utf-8'
    };
    if (methodData === 'POST') {
      requestSettings.url = requestSettings.url + '/insert';
      // requestSettings.url = requestSettings.url + '/insert/' + key;
    } else if (methodData === 'PUT') {
      requestSettings.contentType = 'application/x-www-form-urlencoded; charset=utf-8';
      requestSettings.url = requestSettings.url + '/update/' + key;
    } else if (methodData === 'DELETE') {
      requestSettings.contentType = 'application/x-www-form-urlencoded; charset=utf-8';
      requestSettings.url = requestSettings.url + '/delete/' + key;
    } else if ( methodData === 'GET') {
      requestSettings.url = requestSettings.url + (requestSettings.url.indexOf('?') > 0 ? '&_request=' : '?_request=') + requestSettings.data._request;
    }

    return this.httpClient.request(
      new HttpRequest(requestSettings.method, requestSettings.url, requestSettings.data,
        {headers: new HttpHeaders('Content-Type:' + requestSettings.contentType)}));
  }

  uploadDataService(arg: any, blobData, format, fileName) {
    const status = {};
    const formData = new FormData();
    formData.append('data', blobData);
    formData.append('contentType', format);
    formData.append('fileName', fileName);

    const req = new HttpRequest('POST', environment.api_url + arg, formData, {
      reportProgress: true
    });

    const progress = new Subject<number>();

    this.httpClient.request(req).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {

        // calculate the progress percentage
        const percentDone = Math.round(100 * event.loaded / event.total);

        // pass the percentage into the progress-stream
        progress.next(percentDone);
      } else if (event instanceof HttpResponse) {

        // Close the progress-stream if we get an answer form the API
        // The upload is complete
        progress.complete();
      }
    });

    status[fileName] = {
      progress: progress.asObservable()
    };

    return status;
  }

}
