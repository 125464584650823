import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {LsPathEnum} from '../enumeration/ls-path.enum';
import {UserModel} from '../../models/auth';
import {LevelModel, SatkerModel} from '../../models/reference';
import {isNullOrUndefined, isUndefined} from 'util';
import {BagianModel} from '../../models/reference/bagian.model';
import {HttpParams} from '@angular/common/http';

@Injectable()
export class AppsService {

  constructor() {}

  getYear() {
    return new Date().getFullYear();
  }

  getTA() {
    this.setDefaultTA();
    return localStorage.getItem(`${environment.base_key}${LsPathEnum.LS_TA}`);
  }

  setTA(ta: number) {
      localStorage.setItem(`${environment.base_key}${LsPathEnum.LS_TA}`, String(ta));
  }
  setDefaultTA() {
    const ta = localStorage.getItem(`${environment.base_key}${LsPathEnum.LS_TA}`);
    if (ta == null) {
      localStorage.setItem(`${environment.base_key}${LsPathEnum.LS_TA}`, String((new Date()).getFullYear()));
    }
  }


  getCurrentBagian() {
    // this.setDefaultBagian();
    return localStorage.getItem(`${environment.base_key}${LsPathEnum.LS_BAGIAN}`);
  }

  setCurrentBagian(bagian: any) {
    let currentBagian = null;
    if (bagian === 'KU') {
      currentBagian = '000001';
    } else if (bagian === 'GARA') {
      currentBagian = '000002';
    } else if (bagian === 'SD') {
      currentBagian = '000003';
    } else if (bagian === 'USAHA'){
      currentBagian = '000004';
    }

    localStorage.setItem(`${environment.base_key}${LsPathEnum.LS_BAGIAN}`, String(currentBagian));
  }

  setDefaultBagian() {
    const bagian = localStorage.getItem(`${environment.base_key}${LsPathEnum.LS_BAGIAN}`);
    if (bagian == null) {
      localStorage.setItem(`${environment.base_key}${LsPathEnum.LS_BAGIAN}`, String('000001'));
    }
  }

  getUser(): UserModel {
    const user = localStorage.getItem(`${environment.base_key}${LsPathEnum.LS_USER}`);
    try {
      if (user == null) {
        return null;
      } else {
        return JSON.parse(atob(user.substring(133))) as UserModel;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  }



  getLevel(): LevelModel {
    const levels = localStorage.getItem(`${environment.base_key}${LsPathEnum.LS_LEVEL}`);
    try {
      if (levels == null) {
        return null;
      } else {
        return JSON.parse(atob(levels.substring(75))) as LevelModel;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  getSatker(): SatkerModel {
    const users = this.getUser(); // localStorage.getItem(`${environment.base_key}${LsPathEnum.LS_USER}`);
    if (users == null) {
      return null;
    } else {
      // console.log(users);
      return users.satker != null ? users.satker : null;
    }
  }

  getBagian(): BagianModel {
    const users = localStorage.getItem(`${environment.base_key}${LsPathEnum.LS_USER}`); 
       
    if (users == null) {
      return null;
    } else {
      let user: UserModel;
      user = JSON.parse(atob(users.substring(133))) as UserModel;
      // console.log(user);
      
      return user.bagian != null ? user.bagian : null;
    }
  }

  getDefaultBodyParams(extra: any = null) {
    let bagian = null;
    if (this.getBagian() !== null) {
      if (this.getCurrentBagian() !== null) {
        bagian = this.getCurrentBagian();
      } else {
        bagian = this.getBagian().MappingCode;
      } 
    } 

    const defaultParam = this.getBagian() !== null ?
      {TA: this.getTA(), SATKER_CODE: this.getSatker().KodePaSatker, section: bagian} :
      {TA: this.getTA(), SATKER_CODE: this.getSatker().KodePaSatker};
    return this.getBodyParams(defaultParam, extra);
  }

  getDefaultParams(extra: any = null) {
    let bagian = null;
    
    if (this.getBagian() !== null) {
      if (this.getCurrentBagian() !== null) {
        console.log('bagianif', bagian);
        bagian = this.getCurrentBagian();
        
      } else {
        bagian = this.getBagian().MappingCode;
        console.log('bagian', bagian);

        
      } 
    } 

    const defaultParam = this.getBagian() !== null ?
      {TA: this.getTA(), SATKER_CODE: this.getSatker().KodePaSatker, section: bagian} :
      {TA: this.getTA(), SATKER_CODE: this.getSatker().KodePaSatker};
    return `?${this.getHttpParams(defaultParam, extra)}`;
    // const addExtra = isNullOrUndefined(extra) ? '' : extra;
    // return this.getBagian() !== null
    //   ? `?TA=${this.getTA()}&SATKER_CODE=${this.getSatker().KodePaSatker}&BAGIAN_CODE=${this.getBagian().MappingCode}${addExtra}`
    //   : `?TA=${this.getTA()}&SATKER_CODE=${this.getSatker().KodePaSatker}${addExtra}`;
  }

  getDefaultSegmentParams(extra: any = null) {
    const defaultParam = this.getBagian() !== null ?
      {TA: this.getTA(), SATKER_CODE: this.getSatker().KodePaSatker, section: this.getBagian().MappingCode} :
      {TA: this.getTA(), SATKER_CODE: this.getSatker().KodePaSatker};
    return `${this.getHttpSegmentParams(defaultParam, extra)}`;
    // const addExtra = isNullOrUndefined(extra) ? '' : '/' + extra;
    // return this.getBagian() !== null
    //   ? `TA/${this.getTA()}/SATKER_CODE/${this.getSatker().KodePaSatker}/BAGIAN_CODE/${this.getBagian().MappingCode}${addExtra}`
    //   : `TA/${this.getTA()}/SATKER_CODE/${this.getSatker().KodePaSatker}${addExtra}`;
  }

  getBodyParams(query: any, extra: any = null) {
    const params = query;

    if (!isNullOrUndefined(extra)) {
      for (const key of Object.keys(extra)) {
        if (extra[key]) {
          if (extra[key] instanceof Array) {
            params[key] = extra[key];
            // extra[key].forEach((item) => {
            //   params[key].push(item);
            // });
          } else {
            params[key] = extra[key];
          }
        }
      }
    }

    return params;
  }

  getHttpParams(query: any, extra: any = null) {
    let params: HttpParams = new HttpParams();
    for (const key of Object.keys(query)) {
      if (query[key]) {
        if (query[key] instanceof Array) {
          query[key].forEach((item) => {
            params = params.append(`${key.toString()}[]`, item);
          });
        } else {
          params = params.append(key.toString(), query[key]);
        }
      }
    }

    if (!isNullOrUndefined(extra)) {
      for (const key of Object.keys(extra)) {
        if (extra[key]) {
          if (extra[key] instanceof Array) {
            extra[key].forEach((item) => {
              params = params.append(`${key.toString()}[]`, item);
            });
          } else {
            params = params.append(key.toString(), extra[key]);
          }
        }
      }
    }

    return params;
  }

  getHttpSegmentParams(query: any, extra: any = null) {
    let params = '';
    for (const key of Object.keys(query)) {
      if (query[key]) {
        if (query[key] instanceof Array) {
          query[key].forEach((item) => {
            params = `${params}${key.toString()}[]/${item}/`;
          });
        } else {
          params = `${params}${key.toString()}/${query[key]}/`;
        }
      }
    }

    if (!isNullOrUndefined(extra)) {
      for (const key of Object.keys(extra)) {
        if (extra[key]) {
          if (extra[key] instanceof Array) {
            extra[key].forEach((item) => {
              params = `${params}${key.toString()}[]/${item}/`;
            });
          } else {
            params = `${params}${key.toString()}/${extra[key]}/`;
          }
        }
      }
    }

    return params.substring(0, params.length - 1);
  }

  fixPercentage(amount) {
    if (isNaN(amount) || amount === '-Infinity' || amount === undefined) {
      return '0%';
    } else {
      return amount.toFixed(2) + '%';
    }
  }

  fixPercentageNumber(amount): number {
    if (isNaN(amount) || amount === '-Infinity' || amount === undefined) {
      return 0.00;
    } else {
      return amount.toFixed(2);
    }
  }

  // getMakeRand(strLength: number) {
  //   let text = '';
  //   const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //
  //   for (let i = 0; i < strLength; i++) {
  //     text += possible.charAt(Math.floor(Math.random() * possible.length));
  //   }
  //
  //   return text;
  // }
  //
  // getRandInt(min, max) {
  //   min = Math.ceil(min);
  //   max = Math.floor(max);
  //   return Math.floor(Math.random() * (max - min + 1)) + min;
  // }
  //
  // getMakeCustomBtoa(data: string, ta: number) {
  //   const range = this.getRandInt(11, 99);
  //   const prefix = this.getMakeRand(range);
  //   const result = prefix + btoa(data);
  //   return {
  //     moduleID: range,
  //     tokenizer: result,
  //     TA: ta
  //   };
  // }
  //
  // getResolveCustomBtoa(data: string, range: number) {
  //   return atob(data.substring(range));
  // }

}
