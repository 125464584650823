import {Injectable} from '@angular/core';
import {ReferenceDataModel} from '../../models/reference/reference-data.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {forkJoin} from 'rxjs';
import {ReferencePath} from '../enumeration/reference-path.enum';

@Injectable()
export class ReferenceDataService {
  constructor(private http: HttpClient) {
  }

  getAllMonth() {
    return [
      { monthIndex: 1, monthName: 'JANUARI' },
      { monthIndex: 2, monthName: 'FEBRUARI' },
      { monthIndex: 3, monthName: 'MARET' },
      { monthIndex: 4, monthName: 'APRIL' },
      { monthIndex: 5, monthName: 'MEI' },
      { monthIndex: 6, monthName: 'JUNI' },
      { monthIndex: 7, monthName: 'JULI' },
      { monthIndex: 8, monthName: 'AGUSTUS' },
      { monthIndex: 9, monthName: 'SEPTEMBER' },
      { monthIndex: 10, monthName: 'OKTOBER' },
      { monthIndex: 11, monthName: 'NOVEMBER' },
      { monthIndex: 12, monthName: 'DESEMBER' }
    ];
  }

  getAllExpands() {
    return [
      { expandIndex: 1, expandName: 'KEGIATAN' },
      { expandIndex: 2, expandName: 'OUTPUT' },
      { expandIndex: 3, expandName: 'SUB OUTPUT' },
      { expandIndex: 4, expandName: 'KOMPONEN' },
      { expandIndex: 5, expandName: 'SUB KOMPONEN' }
    ];
  }

  getAllTypes() {
    return [
      { typeIndex: 1, typeName: 'BLU dan RM', typeCode: null },
      { typeIndex: 2, typeName: 'BLU', typeCode: 'F' },
      { typeIndex: 3, typeName: 'RM', typeCode: 'A' }
    ];
  }

  getReferenceData(TA: number): ReferenceDataModel {
    const result = new ReferenceDataModel();
    const ministry =
      this.http.get(environment.base_url + 'assets/' + ReferencePath.MINISTRY_REF).toPromise();
    const echelon =
      this.http.get(environment.base_url + 'assets/' + ReferencePath.ECHELON_REF).toPromise();
    const unit =
      this.http.get(environment.base_url + 'assets/' + ReferencePath.UNIT_REF).toPromise();
    const section =
      this.http.get(environment.base_url + 'assets/' + ReferencePath.SECTION_REF).toPromise();
    // const program =
    //   this.http.get(environment.base_url + 'assets/' + ReferencePath.PROGRAM_REF + '-' + TA.toString() + '.json').toPromise();
    // const activity =
    //   this.http.get(environment.base_url + 'assets/' + ReferencePath.ACTIVITY_REF + '-' + TA.toString() + '.json').toPromise();
    // const output =
    //   this.http.get(environment.base_url + 'assets/' + ReferencePath.OUTPUT_REF + '-' + TA.toString() + '.json').toPromise();
    // const subOutput =
    //   this.http.get(environment.base_url + 'assets/' + ReferencePath.SUB_OUTPUT_REF + '-' + TA.toString() + '.json').toPromise();
    // const component =
    //   this.http.get(environment.base_url + 'assets/' + ReferencePath.COMPONENT_REF + '-' + TA.toString() + '.json').toPromise();
    // const subComponent =
    //   this.http.get(environment.base_url + 'assets/' + ReferencePath.SUB_COMPONENT_REF + '-' + TA.toString() + '.json').toPromise();
    // const akun =
    //   this.http.get(environment.base_url + 'assets/' + ReferencePath.AKUNLV6_REF + '-' + TA.toString() + '.json').toPromise();
    const authority =
      this.http.get(environment.base_url + 'assets/' + ReferencePath.AUTHORITY_REF).toPromise();
    const funds =
      this.http.get(environment.base_url + 'assets/' + ReferencePath.FUNDS_REF).toPromise();
    const location =
      this.http.get(environment.base_url + 'assets/' + ReferencePath.LOCATION_REF).toPromise();
    const region =
      this.http.get(environment.base_url + 'assets/' + ReferencePath.REGION_REF).toPromise();

    forkJoin([ministry, echelon, unit, section, authority, funds, location, region]).subscribe(results => {
      result.KEMENTERIAN_DATA = results[0];
      result.ESELON1_DATA = results[1];
      result.SATKER_DATA = results[2];
      result.BAGIAN_DATA = results[3];
      // result.PROGRAM_DATA = results[4];
      // result.KEGIATAN_DATA = results[5];
      // result.OUTPUT_DATA = results[6];
      // result.SUB_OUTPUT_DATA = results[7];
      // result.KOMPONEN_DATA = results[8];
      // result.SUB_KOMPONEN_DATA = results[9];
      // result.AKUNLV6_DATA = results[10];
      result.KEWENANGAN_DATA = results[4];
      result.SUMBERDANA_DATA = results[5];
      result.LOKASI_DATA = results[6];
      result.WILAYAH_DATA = results[7];
    });
    // forkJoin([ministry, echelon, unit, section, program, activity, output, subOutput,
    //   component, subComponent, akun, authority, funds, location, region]).subscribe(results => {
    //   result.KEMENTERIAN_DATA = results[0];
    //   result.ESELON1_DATA = results[1];
    //   result.SATKER_DATA = results[2];
    //   result.BAGIAN_DATA = results[3];
    //   result.PROGRAM_DATA = results[4];
    //   result.KEGIATAN_DATA = results[5];
    //   result.OUTPUT_DATA = results[6];
    //   result.SUB_OUTPUT_DATA = results[7];
    //   result.KOMPONEN_DATA = results[8];
    //   result.SUB_KOMPONEN_DATA = results[9];
    //   result.AKUNLV6_DATA = results[10];
    //   result.KEWENANGAN_DATA = results[11];
    //   result.SUMBERDANA_DATA = results[12];
    //   result.LOKASI_DATA = results[13];
    //   result.WILAYAH_DATA = results[14];
    // });

    return result;
  }

  getCharacterOfPayments() {
    return [
      // {ID: 3, name: 'GUP'},
      {ID: 3, name: 'Ganti Uang Persediaan'},
      // {ID: 4, name: 'LS'},
      {ID: 4, name: 'Pembayaran Langsung'},
      {ID: 5, name: 'NIHIL'},
    ];
  }

  getHowToPayments() {
    return [
      {ID: 2, name: 'GIRO BANK'},
      {ID: 5, name: 'NIHIL'}
    ];
  }

  getTaxItems() {
    return [
      {ID: 1, name: 'PPn DN'},
      {ID: 2, name: 'PPh 21'},
      {ID: 3, name: 'PPh 22'},
      {ID: 4, name: 'PPh 23'},
      {ID: 5, name: 'FINAL'},
      {ID: 6, name: 'PPh 22 & 23'},
      {ID: 7, name: 'NON PAJAK'},
      {ID: 8, name: 'PPh 23 NON PPn'},
    ];
  }

  getRanksData() {
    return [
      {ID: '1',	Name: 'Pembina Utama (IV/e)'},
      {ID: '2',	Name: 'Pembina Utama Madya (IV/d)'},
      {ID: '3',	Name: 'Pembina Utama Muda (IV/c)'},
      {ID: '4',	Name: 'Pembina Tk. I (IV/b)'},
      {ID: '5',	Name: 'Pembina (IV/a)'},
      {ID: '6',	Name: 'Penata Tk. I (III/d)'},
      {ID: '7',	Name: 'Penata (III/c)'},
      {ID: '8',	Name: 'Penata Muda Tk. I (III/b)'},
      {ID: '9',	Name: 'Penata Muda (III/a)'},
      {ID: '10', Name: 'Pengatur Tk. I (II/d)'},
      {ID: '11', Name: 'Pengatur (II/c)'},
      {ID: '12', Name: 'Pengatur Muda Tk. I (II/b)'},
      {ID: '13', Name: 'Pengatur Muda (II/a)'},
      {ID: '14', Name: 'Juru Tk. I (I/d)'},
      {ID: '15', Name: 'Juru (I/c)'},
      {ID: '16', Name: 'Juru Muda Tk. I (I/b)'},
      {ID: '17', Name: 'Juru Muda (I/a)'}
    ];
  }

  getSectionData() {
    return [
      {PK: 1, ID: '000001', Name: 'KU', Detail: 'Bagian Keuangan dan Umum', Full: '( KU ) Bagian Keuangan dan Umum'},
      {PK: 2, ID: '000002', Name: 'GARA', Detail: 'Bidang Penyelenggaraan', Full: '( GARA ) Bidang Penyelenggaraan'},
      {PK: 3, ID: '000003', Name: 'SD', Detail: 'Bidang Sumber Daya', Full: '( SD ) Bidang Sumber Daya'},
      {PK: 4, ID: '000004', Name: 'USAHA', Detail: 'Bidang Usaha', Full: '( USAHA ) Bidang Usaha'}
    ];
  }
}
