import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private injector: Injector, private toastr: ToastrService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authenticationService = this.injector.get(AuthService);
        return next.handle(request)
      .pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            if (event.status === 200 && authenticationService.loggedIn()) {
              if (event.hasOwnProperty('body')) {
                if (event.body.hasOwnProperty('token')) {
                  authenticationService.setToken(event.body.token);
                }

                if (event.body.hasOwnProperty('success') && !event.body.success) {
                  if (event.body.hasOwnProperty('isError') && event.body.isError) {
                    if (event.body.error.code === 404) {
                      this.toastr.error('REQUEST PAGE IS NOT FOUND');
                      // return throwError(event.body.message);
                    }

                    if (event.body.error.code === 500) {
                      this.toastr.error(`${event.body.error.message}  - PLEASE CONTACT ADMINISTRATOR`);
                      // return throwError(event.body.message);
                    }
                  } else if (event.body.hasOwnProperty('isValidationError') && event.body.isValidationError) {
                    this.toastr.warning(`${event.body.validationMessages[0]}`, 'VALIDATION EXCEPTION');
                    // return throwError(event.body.validationMessages);
                  } else {
                    this.toastr.warning(`${event.body.message}`, 'WARNING');
                    // return throwError(event.body.message);
                  }
                }
              }
            }

            if (event.status === 404 && authenticationService.loggedIn()) {
              if (event.hasOwnProperty('body')) {
                if (event.body.hasOwnProperty('token')) {
                  authenticationService.setToken(event.body.token);
                }
              }
            }
            // console.warn(event.status + authenticationService.loggedIn());
            if (event.status === 422 && authenticationService.loggedIn()) {
              if (event.hasOwnProperty('body')) {
                console.warn(event.status + 'body found');
                if (event.body.hasOwnProperty('success') && !event.body.success) {
                  console.warn(event.status + 'body found success false');
                  if (event.body.hasOwnProperty('isError') && event.body.isError) {
                    console.warn(event.status + 'body found iserror');
                    if (event.body.error.code === 422) {
                      this.toastr.error(`${event.body.error.message}`);
                      return throwError(event.body.message);
                    }
                  }
                }
              }
            }
          }

          return event;
        },
        err => {
          if (err.status === 401) {
            this.toastr.error('Unauthorized');
            // auto logout if 401 response returned from api
             authenticationService.logout();
             location.reload(true);
          }

          const error = err || err.statusText;
          // const error = err.error.message || err.statusText;
          return throwError(error);
        }
      ));
    }
}
