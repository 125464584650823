import {LevelModel, SatkerModel} from '../reference/';
import {ProfileModel} from './profile.model';
import {BagianModel} from '../reference/bagian.model';
import {EmployeeModel} from '../data/employee.model';

export class UserModel {
  id: number;
  name: string;
  email: string;
  password: string;
  userTypeID: number;
  empID: number;
  employee: EmployeeModel;
  levelID: number;
  level: LevelModel;
  satkerID: number;
  satker: SatkerModel;
  bagianID: number;
  bagian: BagianModel;
  active: number;
  nip: string;
  position: string;
  phone: string;
  // tslint:disable-next-line:variable-name
  api_token: string;
  profile: ProfileModel;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.email = data.email;
    this.password = data.password;
    this.userTypeID = data.userTypeID;
    this.empID = data.empID;
    this.employee = data.has_employee !== null ? new EmployeeModel(data.has_employee) : null;
    this.levelID = data.levelID;
    this.level = data.has_level as LevelModel;
    this.satkerID = data.satkerID;
    this.satker = data.has_satker as SatkerModel;
    this.bagianID = data.bagianID;
    this.bagian = data.has_bagian as BagianModel;
    this.active = data.active;
    this.nip = data.nip;
    this.position = data.position;
    this.phone = data.phone;
    this.api_token = data.api_token;
    this.profile = data.has_user_profile as ProfileModel;
  }

}
