export class ReferenceDataModel {
  KEMENTERIAN_DATA: any;
  ESELON1_DATA: any;
  SATKER_DATA: any;
  BAGIAN_DATA: any;
  PROGRAM_DATA: any;
  KEGIATAN_DATA: any;
  OUTPUT_DATA: any;
  SUB_OUTPUT_DATA: any;
  KOMPONEN_DATA: any;
  SUB_KOMPONEN_DATA: any;
  AKUNLV6_DATA: any;
  KEWENANGAN_DATA: any;
  SUMBERDANA_DATA: any;
  LOKASI_DATA: any;
  WILAYAH_DATA: any;
}
