import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ReportingBase} from '../../systems/base/reporting/reporting-base';
import {HttpClient} from '@angular/common/http';
declare var Stimulsoft: any;
@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.css']
})
export class ReportingComponent implements OnInit, OnChanges {
  @Input() dataReporting: ReportingBase;
  @Input() showReporting: boolean;
  @Output() hideReporting = new EventEmitter<boolean>();
  isShow = false;
  viewer: any = new Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);
  report: any = new Stimulsoft.Report.StiReport();
  constructor(private httpClient: HttpClient) {
    Stimulsoft.Base.StiLicense.key = '6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHl2AD0gPVknKsaW0un+3PuM6TTcPMUAWEURKXNso0e5OFPaZYas' +
      'FtsxNoDemsFOXbvf7SIcnyAkFX/4u37NTfx7g+0IqLXw6QIPolr1PvCSZz8Z5wjBNakeCVozGGOiuCOQDy60XNqfbgrOjxgQ5y/u54K4g7R/xuWmpdx5OMAbUbc' +
      'y3WbhPCbJJYTI5Hg8C/gsbHSnC2EeOCuyA9ImrNyjsUHkLEh9y4WoRw7lRIc1x+dli8jSJxt9C+NYVUIqK7MEeCmmVyFEGN8mNnqZp4vTe98kxAr4dWSmhcQahH' +
      'GuFBhKQLlVOdlJ/OT+WPX1zS2UmnkTrxun+FWpCC5bLDlwhlslxtyaN9pV3sRLO6KXM88ZkefRrH21DdR+4j79HA7VLTAsebI79t9nMgmXJ5hB1JKcJMUAgWpxT' +
      '7C7JUGcWCPIG10NuCd9XQ7H4ykQ4Ve6J2LuNo9SbvP6jPwdfQJB6fJBnKg4mtNuLMlQ4pnXDc+wJmqgw25NfHpFmrZYACZOtLEJoPtMWxxwDzZEYYfT';
  }

  ngOnInit() {
    console.log(this.dataReporting);
    console.log(this.showReporting);
    const options = new Stimulsoft.Viewer.StiViewerOptions();
    // StiOptions.Engine.UseAdvancedPrintOnEngineV2 = true;
    options.height = `100%`;
    // options.toolbar.zoom = Stimulsoft.Viewer.StiZoomMode.PageHeight;
    options.toolbar.zoom = Stimulsoft.Viewer.StiZoomMode.PageWidth;
    options.appearance.scrollbarsMode = true;
    options.appearance.showTooltips = false;
    options.toolbar.showFullScreenButton = true;
    options.toolbar.showAboutButton = false;
    options.exports.showExportToDocument = false;
    options.appearance.interfaceType = Stimulsoft.Viewer.StiInterfaceType.Auto;
    options.toolbar.printDestination = Stimulsoft.Viewer.StiPrintDestination.Direct;
    options.appearance.htmlRenderMode = Stimulsoft.Report.Export.StiHtmlExportMode.Table;
    this.viewer = new Stimulsoft.Viewer.StiViewer(options, 'StiViewer', false);
    this.viewer.report = this.report;
    this.viewer.renderHtml('viewer');
    // StiPrintProvider.QueryPageSettings(StiReport report, StiQueryPageSettingsEventArgs e)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dataReporting.forceDownload) {
      setTimeout(async () => {
        this.isShow = false;
        this.httpClient.get(`assets/reports/${this.dataReporting.path}.mrt`).toPromise().then((data: any) => {
          const report = new Stimulsoft.Report.StiReport();
          this.dataReporting.sources.forEach((value) => {
            data.Dictionary.Databases[`${value.index}`].PathData = `${value.path}`;
          });

          report.load(data);
          let exportService: any;
          let exportFormat: any = null;
          let exportSetting: any;
          let mimeFormat: string = null;
          let ext: string = null;
          let fileName = 'report';
          switch (this.dataReporting.format) {
            case 'PDF':
              mimeFormat = 'application/pdf';
              exportFormat = Stimulsoft.Report.StiExportFormat.Pdf;
              ext = 'pdf';
              break;
            case 'DOCX':
              mimeFormat = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
              exportFormat = Stimulsoft.Report.StiExportFormat.Word2007;
              ext = 'docx';
              break;
            default:
              //XLSX
              mimeFormat = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
              exportFormat = Stimulsoft.Report.StiExportFormat.Excel2007;
              exportService = new Stimulsoft.Report.Export.StiExcel2007ExportService();
              exportSetting = new Stimulsoft.Report.Export.StiExcel2007ExportSettings();
              exportSetting.useOnePageHeaderAndFooter = true;
              ext = 'xlsx';
              break;
          }

          report.renderAsync((e) => {
            const data = report.exportDocument(exportFormat, exportService, exportSetting);
            (Object as any).saveAs(data, fileName + '.' + ext, mimeFormat);
            console.log('DONE');
          });
        });
      }, 50);
    } else {
      console.log(this.dataReporting);
      console.log(this.showReporting);
      this.viewer.showProcessIndicator();
      setTimeout(async () => {
        this.isShow = this.showReporting;
        if (this.dataReporting !== null) {
          this.httpClient.get(`assets/reports/${this.dataReporting.path}.mrt`).toPromise().then((data: any) => {
            const report = new Stimulsoft.Report.StiReport();
            this.dataReporting.sources.forEach((value) => {
              data.Dictionary.Databases[`${value.index}`].PathData = `${value.path}`;
            });
            report.load(data);
            this.viewer.report = report;

          });
        }
      }, 50);
    }



  }

  onRequestHideClick(e: MouseEvent) {
    this.hideReporting.emit(true);
  }
}
